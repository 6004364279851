import { Button, TextField } from "@mui/material";
import { textStyle } from "../../../styles/text";

export const BarraPesquisa = ({ query, setQuery, header }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			flexWrap: "wrap",
			marginBottom: "10px",
		}}
	>
		{header ? <p style={textStyle.header}>{header}</p> : null}
		<div style={{ alignItems: "center", display: "flex" }}>
			<TextField
				variant="outlined"
				label="ID Agrisus"
				sx={{ backgroundColor: "white" }}
				value={query}
				onChange={(event) => setQuery(event.target.value)}
			/>
			<Button
				style={{ color: "white", backgroundColor: "black", marginLeft: "10px", height: "fit-content" }}
				onClick={() => {
					setQuery("");
				}}
			>
				LIMPAR
			</Button>
		</div>
	</div>
);
