import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";
import { atualizarAssessor } from "./api";
import notificar from "../../../components/notificar";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function ModificarAssessor(props) {
	const [nome, setNome] = React.useState(props.nome);
	const [email, setEmail] = React.useState(props.email);
	const [senha, setSenha] = React.useState("");

	const handleUpdate = async () => {
		try {
			let query = await atualizarAssessor({ id: props.id, nome, email, senha });

			if (query.status === 200) {
				await query.json();
				notificar("Atualizado com sucesso!", "success");
				handleClose();
				props.reloadData();
			} else {
				let json = await query.json();
				notificar(json, "error");
			}
		} catch (error) {
			notificar("Erro ao atualizar assessor!", "error");
			console.error(error);
		}
	};

	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} TransitionComponent={Transition}>
				<DialogTitle>Atualizar Cadastro do Assessor</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Caso deixe o campo de senha em branco, a senha atual será mantida.
					</DialogContentText>
					<TextField
						value={nome}
						onChange={(event) => setNome(event.target.value)}
						autoFocus
						margin="dense"
						id="name"
						label="Nome"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						autoFocus
						margin="dense"
						id="email"
						label="Email"
						type="email"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={senha}
						onChange={(event) => setSenha(event.target.value)}
						autoFocus
						margin="dense"
						id="password"
						label="Nova Senha"
						type="password"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Voltar</Button>
					<Button onClick={handleUpdate}>Atualizar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
