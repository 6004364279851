import Login from "./views/Login";
import Pesquisador from "./views/Pesquisador";
import { Registro, Registrar, Sucesso, Confirmacao, RedefinirSenha, EsqueciMinhaSenha } from "./views/Registro";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Acompanhamento from "./views/Pesquisador/Acompanhamento";
import Secretaria from "./views/Secretaria";
import Assessor from "./views/Assessor";
import Projetos from "./views/Secretaria/TabelaProjetos";
import ProjetosAssessor from "./views/Assessor/TabelaProjetos";
import Acessores from "./views/Secretaria/Acessores";
import Coordenadores from "./views/Secretaria/Coordenadores";
import DetalhesProposta from "./views/Secretaria/DetalhesProposta";
import DetalhesPropostaAssessor from "./views/Assessor/DetalhesProposta";
import EmitirParecer from "./views/Assessor/EmitirParecer";
import EmitirParecerSec from "./views/Secretaria/EmitirParecer";
import { Configuracoes } from "./views/Secretaria/Configuracoes";
import SubmissaoCat1 from "./views/Pesquisador/Submissao/documentacao-cat1";
import SubmissaoCat2 from "./views/Pesquisador/Submissao/documentacao-cat2";
import SubmissaoCat3 from "./views/Pesquisador/Submissao/documentacao-cat3";
import { Submissao, SelecaoCategoria } from "./views/Pesquisador/Submissao/selecao_categoria";
import { Conta } from "./views/Secretaria/Conta";
import { Conta as ContaAssessor } from "./views/Assessor/Conta";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/registro" element={<Registro />}>
					<Route index element={<Registrar />} />
					<Route path="registrar" element={<Registrar />} />
					<Route path="sucesso" element={<Sucesso />} />
					<Route path="confirmacao/:token" element={<Confirmacao />} />
					<Route path="redefinir-senha/:token" element={<RedefinirSenha />} />
					<Route path="esqueci-minha-senha" element={<EsqueciMinhaSenha />} />
				</Route>
				<Route path="/pesquisador" element={<Pesquisador />}>
					<Route index element={<Acompanhamento />} />
					<Route path="submissao" element={<Submissao />}>
						<Route index element={<SelecaoCategoria />} />
						<Route path="categoria-1" element={<SubmissaoCat1 />} />
						<Route path="categoria-2" element={<SubmissaoCat2 />} />
						<Route path="categoria-3" element={<SubmissaoCat3 />} />
					</Route>
				</Route>
				<Route path="/secretaria" element={<Secretaria />}>
					<Route index element={<Projetos />} />
					<Route path="acessores" element={<Acessores />} />
					<Route path="propostas-arquivadas" element={<Projetos arquivo={true} />} />
					<Route path="coordenadores" element={<Coordenadores />} />
					<Route path="detalhes-proposta/:idProposta" element={<DetalhesProposta />} />
					<Route path="emitir-parecer/:idProposta/:categoria" element={<EmitirParecerSec />} />
					<Route path="conta" element={<Conta />} />
					<Route path="configuracoes" element={<Configuracoes />} />
				</Route>
				<Route path="/assessor" element={<Assessor />}>
					<Route index element={<ProjetosAssessor />} />
					<Route path="detalhes-proposta/:idProposta" element={<DetalhesPropostaAssessor />} />
					<Route path="conta" element={<ContaAssessor />} />
					<Route path="emitir-parecer/:idProposta/:categoria" element={<EmitirParecer />} />
				</Route>
				<Route path="*" element={<h1>404</h1>} />
			</Routes>
		</BrowserRouter>
	);
}
