import Anexo from "./anexo";
import notificar from "../notificar";
import React from "react";

export const AnexoCoordenacao = ({ text, dbColumn, file, id, reloadData, sx, excel, pdf }) => {
	const [value, setValue] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const enviar = () => {
		if (value) enviarDocumento(value, dbColumn, id, reloadData, setLoading);
	};

	return (
		<Anexo
			sx={{ ...sx }}
			excel={excel}
			pdf={pdf}
			text={text}
			value={value}
			setValue={setValue}
			enviar={enviar}
			file={file}
			loading={loading}
		/>
	);
};

const enviarDocumento = async (value, dbColumn, id, reloadData, setLoading) => {
	setLoading(true);
	let formData = new FormData();

	if (
		dbColumn === "planilha_orcamentaria_cat1.xlsx" ||
		dbColumn === "planilha_orcamentaria_cat2.xlsx" ||
		dbColumn === "planilha_orcamentaria_cat3.xlsx"
	) {
		formData.append("plano_de_recursos", value);
	} else {
		formData.append(dbColumn, value);
	}
	formData.append("id", id);
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	let response = await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/enviar-documento`,
		{
			method: "PUT",
			body: formData,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);

	if (response.status === 200) {
		setLoading(false);
		reloadData();
		notificar("Documento enviado com sucesso!", "success");
	} else {
		setLoading(false);
		notificar("Erro ao enviar documento!", "error");
	}
};
