import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import useUserStore from "../../../stores/user";
import notificar from "../../../components/notificar";
import { textStyle } from "../../../styles/text";

const sx = {
	backgroundColor: "white",
};

export const Conta = () => {
	const [nome, setNome] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [senha, setSenha] = React.useState("");
	const [novaSenha, setNovaSenha] = React.useState("");

	React.useEffect(() => {
		let user = JSON.parse(sessionStorage.getItem("user"));
		user = user.state;
		setNome(user.nome);
		setEmail(user.email);
	}, []);

	const handleInputValid = () => {
		if (nome === "" && senha === "" && novaSenha === "") return false;
		if (senha === "" && novaSenha !== "") {
			notificar("Para alterar a senha, preencha ambos os campos", "error");
			return false;
		}
		if (senha !== "" && novaSenha === "") {
			notificar("Para alterar a senha, preencha ambos os campos", "error");
			return false;
		}

		return true;
	};

	const handleSave = async () => {
		if (!handleInputValid()) {
			console.log("invalid input");
			return;
		}
		const user = useUserStore.getState();

		let data = {};
		if (nome !== "") data.nome = nome;
		if (novaSenha !== "") data.senha = novaSenha;

		const response = await user.updateAssessor(data);
		if (response) {
			notificar("Dados atualizados com sucesso", "success");
			setSenha("");
			setNovaSenha("");
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<p style={textStyle.header}>Minha Conta</p>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "fit-content",
					minWidth: "300px",
					rowGap: "10px",
				}}
			>
				<Typography>Dados Pessoais</Typography>
				<TextField sx={sx} label="Nome" value={nome} onChange={(event) => setNome(event.target.value)} />
				<TextField sx={sx} disabled label="Email" value={email} />

				<Typography>Alterar Senha</Typography>
				<TextField
					sx={sx}
					type="password"
					label="Senha"
					value={senha}
					onChange={(event) => setSenha(event.target.value)}
				/>
				<TextField
					sx={sx}
					type="password"
					label="Nova Senha"
					value={novaSenha}
					onChange={(event) => setNovaSenha(event.target.value)}
				/>
				<Button sx={{ width: "fit-content" }} variant="outlined" onClick={handleSave}>
					Salvar
				</Button>
			</Box>
		</Box>
	);
};
