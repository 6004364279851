import {
	Box,
	Typography,
	TextField,
	FormControlLabel,
	Switch,
	Button,
	CircularProgress,
	Tab,
	Tabs,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import notificar from "../../../components/notificar";
import { AnexoConfiguracoes } from "../../../components/anexoConfiguracoes";
import { textStyle } from "../../../styles/text";

export const Configuracoes = () => {
	const [tab, setTab] = useState(0);

	return (
		<Box
			sx={{
				backgroundColor: "transparent",
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<p style={textStyle.header}>Configurações do Sistema</p>
			<Tabs
				variant="scrollable"
				value={tab}
				onChange={(event, newValue) => setTab(newValue)}
				sx={{ height: "fit-content", color: "white" }}
			>
				<Tab label="Edital" />
				<Tab label="Docs. Edital" />
				<Tab label="Docs. Pós Aprovação" />
			</Tabs>
			{tab === 0 ? <Edital /> : null}
			{tab === 1 ? <DocsEdital /> : null}
			{tab === 2 ? <DocsPosAprovacao /> : null}
		</Box>
	);
};

const Edital = () => {
	const [isLoading, setLoading] = useState(true);
	const [receberPropostas, setReceberPropostas] = useState(null);
	const [editalAtual, setEditalAtual] = useState(null);
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	const loadData = async () => {
		const request = await axios
			.get(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/configuracoes/listar`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.catch((err) => console.error(err));
		if (request.status === 200) {
			setEditalAtual(request.data.edital_atual);
			setReceberPropostas(request.data.receber_propostas);
			setLoading(false);
		}
	};

	const handleSave = async () => {
		setLoading(true);
		const request = await axios
			.post(
				`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/configuracoes/salvar`,
				{
					edital_atual: editalAtual,
					receber_propostas: receberPropostas,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.catch((err) => console.error(err));
		if (request.status === 200) {
			setLoading(false);
			notificar("Salvo com sucesso", "success");
		} else {
			setLoading(false);
			notificar("Erro ao salvar", "error");
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	if (!isLoading)
		return (
			<Box>
				<p style={{ ...textStyle.header2, marginTop: "10px", marginBottom: "10px" }}>Definições do Edital</p>
				<Box sx={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
					<TextField
						value={editalAtual}
						onChange={(event) => setEditalAtual(event.target.value)}
						label="Edital"
						variant="outlined"
						sx={{ width: "fit-content", backgroundColor: "white" }}
					/>
					<Typography variant="caption">Novas propostas serão atribuídas ao edital definido acima</Typography>
					<FormControlLabel
						control={
							<Switch
								checked={receberPropostas}
								onChange={(event) => setReceberPropostas(event.target.checked)}
							/>
						}
						sx={{ marginTop: "10px" }}
						label="Receber Propostas"
					/>
					<Typography variant="caption">
						Quando habilitado, permite submissão de novas propostas. Desabilite para negar
					</Typography>

					<div>
						<Button variant="outlined" sx={{ marginTop: "10px" }} onClick={handleSave}>
							SALVAR
						</Button>
					</div>
				</Box>
			</Box>
		);
	else return <CircularProgress sx={{ margin: "auto", marginLeft: "auto", alignSelf: "center" }} />;
};

const DocsPosAprovacao = () => {
	const [cadastroCoordenador, setCadastroCoordenador] = useState(null);
	const [formularioBolsa, setFormularioBolsa] = useState(null);
	const [planoAtividades, setPlanoAtividades] = useState(null);
	const [protocoloAberturaEvento, setProtocoloAberturaEvento] = useState(null);
	const [protocoloAberturaPesquisa, setProtocoloAberturaPesquisa] = useState(null);

	return (
		<Box>
			<p style={{ ...textStyle.header2, marginTop: "10px", marginBottom: "10px" }}>Documentação Pós Aprovação</p>
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, flexWrap: "wrap" }}>
				<AnexoConfiguracoes
					text="Cadastro do Coordenador"
					value={cadastroCoordenador}
					setValue={setCadastroCoordenador}
					dbColumn="cadastro_de_coordenador.pdf"
					pdf
					sx={{ flexGrow: 1 }}
				/>
				<AnexoConfiguracoes
					text="Formulario de Concessão de Bolsa"
					value={formularioBolsa}
					setValue={setFormularioBolsa}
					dbColumn="formulario_concessao_bolsa.pdf"
					pdf
					sx={{ flexGrow: 1 }}
				/>
				<AnexoConfiguracoes
					text="Plano de Atividades do Bolsista"
					value={planoAtividades}
					setValue={setPlanoAtividades}
					dbColumn="plano_de_atividades.pdf"
					sx={{ flexGrow: 1 }}
					pdf
				/>
				<AnexoConfiguracoes
					text="Protocolo de Abertura de Evento"
					value={protocoloAberturaEvento}
					setValue={setProtocoloAberturaEvento}
					dbColumn="protocolo_abertura_evento.pdf"
					sx={{ flexGrow: 1 }}
					pdf
				/>
				<AnexoConfiguracoes
					text="Protocolo de Abertura de Pesquisa"
					value={protocoloAberturaPesquisa}
					setValue={setProtocoloAberturaPesquisa}
					pdf
					dbColumn="protocolo_abertura_pesquisa.pdf"
					sx={{ flexGrow: 1 }}
				/>
			</Box>
		</Box>
	);
};

const DocsEdital = () => {
	const [edital, setEdital] = useState(null);

	return (
		<Box>
			<p style={{ ...textStyle.header2, marginTop: "10px", marginBottom: "10px" }}>Documentação do Edital</p>
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, flexWrap: "wrap" }}>
				<AnexoConfiguracoes
					text="Edital"
					value={edital}
					setValue={setEdital}
					dbColumn="edital.pdf"
					pdf
					sx={{ flexGrow: 1 }}
				/>
				<AnexoConfiguracoes
					text="Planilha Orçamentária Categoria 1"
					value={edital}
					setValue={setEdital}
					excel
					dbColumn="planilha_orcamentaria_cat1.xlsx"
					sx={{ flexGrow: 1 }}
				/>
				<AnexoConfiguracoes
					text="Planilha Orçamentária Categoria 2"
					value={edital}
					setValue={setEdital}
					excel
					dbColumn="planilha_orcamentaria_cat2.xlsx"
					sx={{ flexGrow: 1 }}
				/>
				<AnexoConfiguracoes
					text="Planilha Orçamentária Categoria 3"
					value={edital}
					setValue={setEdital}
					excel
					dbColumn="planilha_orcamentaria_cat3.xlsx"
					sx={{ flexGrow: 1 }}
				/>
			</Box>
		</Box>
	);
};
