import React from "react";
import { Box, Button } from "@mui/material";
import { downloadFile } from "../downloadFile";

export default function ArquivoEnviado({ arquivo, length, index, status }) {
	const data = new Date(arquivo.data).toLocaleDateString("pt-BR", {
		timeZone: "UTC",
	});

	let color = "black";

	switch (status) {
		case "PENDENTE":
			color = "orange";
			break;
		case "APROVADO":
			color = "green";
			break;
		case "REPROVADO":
			color = "red";
			break;
		case "AGUARDANDO ANÁLISE":
			color = "orange";
			break;
		default:
			color = "black";
	}

	const statusHandler = () => {
		if (index < length) {
			return (
				<p style={{ margin: 0 }}>
					Status: <span style={{ color: "red" }}>REPROVADO</span>
				</p>
			);
		} else {
			return (
				<p style={{ margin: 0 }}>
					Status: <span style={{ color }}>{status}</span>
				</p>
			);
		}
	};

	if (index < length)
		return (
			<Box
				sx={{
					border: "1px solid rgb(200,200,200)",
					borderRadius: "5px",
					padding: "10px",
				}}
			>
				<Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
					<p style={{ margin: 0 }}>Enviado em: {data}</p>
					<Button sx={{ marginLeft: "auto", height: 0 }} onClick={() => downloadFile(arquivo.arquivo)}>
						Visualizar
					</Button>
				</Box>
				<Box>
					<p style={{ margin: 0 }}>
						<strong>Resposta do comitê</strong>
					</p>
					<Box>
						{statusHandler()}
						{arquivo.resposta_arquivo ? (
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<p style={{ margin: 0 }}>Anexo:</p>
								<Button sx={{ height: 0 }} onClick={() => downloadFile(arquivo.resposta_arquivo)}>
									Visualizar
								</Button>
							</Box>
						) : null}

						{arquivo.resposta_texto ? <p style={{ margin: 0 }}>Texto: {arquivo.resposta_texto}</p> : null}
					</Box>
				</Box>
			</Box>
		);
	else {
		if (status === "REPROVADO" || status === "APROVADO")
			return (
				<Box
					sx={{
						border: "1px solid rgb(200,200,200)",
						borderRadius: "5px",
						padding: "10px",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
						<p style={{ margin: 0 }}>Enviado em: {data}</p>
						<Button sx={{ marginLeft: "auto", height: 0 }} onClick={() => downloadFile(arquivo.arquivo)}>
							Visualizar
						</Button>
					</Box>
					<Box>
						<p style={{ margin: 0 }}>
							<strong>Resposta do comitê</strong>
						</p>
						<Box>
							{statusHandler()}
							{arquivo.resposta_arquivo ? (
								<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
									<p style={{ margin: 0 }}>Anexo:</p>
									<Button sx={{ height: 0 }} onClick={() => downloadFile(arquivo.resposta_arquivo)}>
										Visualizar
									</Button>
								</Box>
							) : null}
							{arquivo.resposta_texto ? (
								<p style={{ margin: 0 }}>Texto: {arquivo.resposta_texto}</p>
							) : null}
						</Box>
					</Box>
				</Box>
			);
		else
			return (
				<Box
					sx={{
						border: "1px solid rgb(200,200,200)",
						borderRadius: "5px",
						padding: "10px",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
						<p style={{ margin: 0 }}>Enviado em: {data}</p>
						<Button sx={{ marginLeft: "auto", height: 0 }} onClick={() => downloadFile(arquivo.arquivo)}>
							Visualizar
						</Button>
					</Box>
					<Box>
						<Box>{statusHandler()}</Box>
					</Box>
				</Box>
			);
	}
}
