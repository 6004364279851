import * as React from "react";
import {
	TextField,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	Box,
} from "@mui/material";
import notificar from "../../../components/notificar";

export function AdicionarRelatorio({ data, reloadData }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setTittle(`Relatório Parcial ${data.relatorios.length + 1}`);
		setOpen(false);
	};

	const [date, setDate] = React.useState("01-01-1970");
	const [tittle, setTittle] = React.useState(`Relatório Parcial ${data.relatorios.length + 1}`);

	React.useEffect(() => {
		let dd = new Date().getDate().toLocaleString("pt-BR", {
			minimumIntegerDigits: 2,
			useGrouping: false,
		});
		let mm = new Date().getUTCMonth() + 1;
		mm = mm.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
		let yyyy = new Date().getFullYear();
		dd = yyyy + "-" + mm + "-" + dd;

		console.log(dd);
		setDate(dd);
	}, []);

	const handleUpdate = async () => {
		let query = await fetch(
			`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/relatorio/adicionar`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					titulo: tittle,
					prazo: date,
					id_proposta: data.id,
				}),
			}
		).catch((err) => console.log(err));

		if (query.status === 200) {
			notificar("Relatório adicionado com sucesso", "success");
			setOpen(false);
			reloadData();
		} else {
			notificar("Erro ao adicionar relatório", "error");
		}
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Adicionar
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Adicionar Relatório</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							rowGap: "10px",
							paddingTop: "10px",
							alignItems: "center",
						}}
					>
						<TextField
							label="Título do Relatório"
							value={tittle}
							onChange={(event) => setTittle(event.target.value)}
						/>
						<input type="date" value={date} onChange={(event) => setDate(event.target.value)} />
						<DialogContentText id="alert-dialog-description">
							Defina o título do relatório e o prazo final para a entrega.
						</DialogContentText>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Sair</Button>
					<Button onClick={handleUpdate} autoFocus>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
