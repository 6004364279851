import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./login.module.css";
import logo from "../../assets/logo.png";
import video from "../../assets/background.mp4";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../stores/user";

export default function Login() {
	const [email, setEmail] = useState("");
	const [senha, setSenha] = useState("");

	const navigate = useNavigate();
	const userStore = useUserStore((state) => state);

	const handleLogin = async () => {
		await userStore.login(email, senha, navigate);
	};

	const loginSecretaria = async () => {
		await userStore.loginSecretaria(email, senha, navigate);
	};

	const loginAssessor = async () => {
		await userStore.loginAssessor(email, senha, navigate);
	};

	return (
		<div id={styles.container}>
			<div id={styles.contentContainer} style={{ width: window.innerWidth < 1920 ? "400px" : "400px" }}>
				<div id={styles.loginForm}>
					<div id={styles.logoContainer}>
						<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />
					</div>
					<div id={styles.secaoFormulario}>
						<p id={styles.header}>ACESSE SUA CONTA</p>
						<TextField
							variant="outlined"
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							label="Email"
						/>
						<TextField
							variant="outlined"
							value={senha}
							onChange={(event) => setSenha(event.target.value)}
							label="Senha"
							type="password"
							style={{ marginTop: "15px" }}
						/>
						<a id={styles.linkSenha} target="_blank" href="/registro/esqueci-minha-senha">
							Esqueci minha senha
						</a>
						<Button id={styles.button} onClick={() => handleLogin()}>
							ACESSAR
						</Button>
						<Button
							id={styles.button}
							style={{ backgroundColor: "black" }}
							onClick={() => loginSecretaria()}
						>
							COORDENAÇÃO EXECUTIVA
						</Button>
						<Button id={styles.button} style={{ backgroundColor: "black" }} onClick={() => loginAssessor()}>
							COMITÊ CIENTÍFICO
						</Button>
						<p id={styles.textoRegistro}>Ainda não possui uma conta?</p>
						<Link to="/registro" style={{ textDecoration: "none" }}>
							<Button id={styles.buttonRegistro}>REGISTRE-SE</Button>
						</Link>
					</div>
				</div>
			</div>
			<div id={styles.imageContainer}>
				<div style={{ backgroundColor: "black", opacity: "0.7" }}>
					<video autoPlay loop muted>
						<source src={video} type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	);
}
