import { Box, TextField, Button, InputAdornment } from "@mui/material";
import React from "react";
import notificar from "../../../components/notificar";

const sx = {
	backgroundColor: "white",
	flexGrow: 1,
};

const handleCategoria = (categoria) => {
	switch (categoria) {
		case 1:
			return "Projeto de Pesquisa sem Bolsa de Iniciação Científica";
		case 2:
			return "Projeto de Pesquisa com Bolsa de Iniciação Científica";
		case 3:
			return "Financiamento de Eventos Técnicos / Demonstrações a campo";
		default:
			return "Outro";
	}
};

const Detalhes = ({ data, setIsLoading, reloadData }) => {
	const variant = "outlined";
	const [resumo_da_proposta, setResumo] = React.useState(data.resumo_da_proposta);
	const [valor_do_projeto, setValor] = React.useState(data.valor_do_projeto);
	const [razao_social_financiadora, setRazaoSocial] = React.useState(data.razao_social_financiadora);
	const [valor_financiamento_externo, setValorFinanciamento] = React.useState(data.valor_financiamento_externo);
	const [inicio_evento, setInicioEvento] = React.useState(data.inicio_evento);
	const [termino_evento, setTerminoEvento] = React.useState(data.termino_evento);

	const handleCurrency = (event, setValue) => {
		let value = event.target.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		setValue(value);
	};

	const handleSave = async () => {
		setIsLoading(true);
		try {
			const user = JSON.parse(sessionStorage.getItem("user"));
			const token = user.state.token;

			await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/editar`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: data.id,
					resumo_da_proposta,
					valor_do_projeto,
					razao_social_financiadora,
					valor_financiamento_externo,
					inicio_evento,
					termino_evento,
				}),
			});
			notificar("Dados salvos com sucesso", "success");
			reloadData();
		} catch {
			notificar("Erro ao salvar os dados", "error");
			setIsLoading(false);
		}
	};

	return (
		<Box
			component="form"
			sx={{
				backgroundColor: "transparent",
				justifyContent: "space-evenly",
				display: "flex",
				flexWrap: "wrap",
				rowGap: "20px",
				columnGap: "15px",
				marginTop: "50px",
				paddingBottom: "50px",
			}}
		>
			<TextField
				value={resumo_da_proposta}
				onChange={(event) => setResumo(event.target.value)}
				label="Titulo da Proposta"
				multiline
				fullWidth
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.pesquisador.nome}
				label="Coordenador"
				multiline
				fullWidth
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField
				value={data.pesquisador.instituicao}
				label="Instituição"
				multiline
				fullWidth
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField value={data.id} label="ID da Proposta" sx={sx} variant={variant} disabled />
			<TextField
				value={data.protocolo_abertura[0].pa + "/" + data.protocolo_abertura[0].ano}
				//onChange={(event) => setIdAgrisus(event.target.value)}
				label="Protocolo de Abertura"
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField
				value={data.id_fealq}
				//onChange={(event) => setIdFealq(event.target.value)}
				label="ID Fealq"
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField
				value={data.edital}
				//onChange={(event) => setEdital(event.target.value)}
				label="Edital"
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField
				value={data.categoria}
				//onChange={(event) => setCategoria(event.target.value)}
				label="Categoria"
				sx={sx}
				variant={variant}
				disabled
			/>
			{data.categoria === 3 ? (
				<>
					<TextField
						value={inicio_evento}
						label="Início do Evento"
						sx={sx}
						variant={variant}
						onChange={(event) => setInicioEvento(event.target.value)}
					/>
					<TextField
						value={termino_evento}
						label="Término do Evento"
						sx={sx}
						variant={variant}
						onChange={(event) => setTerminoEvento(event.target.value)}
					/>
				</>
			) : null}
			<TextField
				fullWidth
				value={handleCategoria(data.categoria)}
				label="Descrição da categoria"
				sx={sx}
				variant={variant}
				disabled
			/>
			<TextField
				value={valor_do_projeto}
				onChange={(event) => handleCurrency(event, setValor)}
				label="Valor Solicitado"
				type="text"
				sx={sx}
				variant={variant}
				InputProps={{
					startAdornment: <InputAdornment position="start">R$</InputAdornment>,
				}}
			/>
			{data.valor_financiamento_externo !== "0" ? (
				<div
					style={{
						flexGrow: 1,
						width: "100%",
						display: "flex",
						columnGap: "15px",
						flexWrap: "wrap",
						rowGap: "20px",
					}}
				>
					<TextField
						value={razao_social_financiadora}
						onChange={(event) => setRazaoSocial(event.target.value)}
						label="Razão Social da Entidade Financiadora Externa"
						sx={sx}
						variant={variant}
					/>
					<TextField
						value={valor_financiamento_externo}
						onChange={(event) => handleCurrency(event, setValorFinanciamento)}
						label="Valor Financiado"
						sx={sx}
						variant={variant}
						InputProps={{
							startAdornment: <InputAdornment position="start">R$</InputAdornment>,
						}}
					/>
				</div>
			) : null}
			<TextField value={data.tempo_de_vigencia} label="Vigência" sx={sx} variant={variant} disabled />
			<Button onClick={handleSave} variant={variant}>
				Salvar
			</Button>
		</Box>
	);
};

export default Detalhes;
