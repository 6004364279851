export async function getProjects(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/lista-proposta`,
		{
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);
}
