import { Warning, CheckBox } from "@mui/icons-material";
import { textStyle } from "../../../styles/text";

const Status = ({ dados }) => {
	if (dados.parecer === "EMITIDO" || dados.parecer === "REPROVADO" || dados.parecer === "APROVADO")
		return (
			<div
				style={{
					display: "flex",
					width: "fit-content",
					alignItems: "center",
				}}
			>
				{dados.parecer === "REPROVADO" || dados.parecer === "EMITIDO" ? (
					<Warning sx={{ color: "orange", marginRight: "5px" }} />
				) : (
					<CheckBox sx={{ color: "green", marginRight: "5px" }} />
				)}
				<p style={textStyle.p}>
					{dados.parecer === "EMITIDO"
						? "Parecer Emitido"
						: dados.parecer === "APROVADO" && !dados.em_andamento
						? "Proposta Aprovada"
						: dados.parecer === "APROVADO" && dados.em_andamento && !propostaFinalizada(dados)
						? "Proposta em Andamento"
						: dados.parecer === "APROVADO" && dados.em_andamento && propostaFinalizada(dados)
						? "Proposta Finalizada"
						: "Proposta não Recomendada"}
				</p>
			</div>
		);
	else
		return (
			<div
				style={{
					borderRadius: "2px",
					display: "flex",
					alignItems: "center",
					width: "fit-content",
				}}
			>
				<Warning sx={{ color: "orange", marginRight: "5px" }} />
				<p>Aguardando análise</p>
			</div>
		);
};

const propostaFinalizada = (data) => {
	if (data.relatorios.length === 0 || !data.relatorios) return false;

	for (let relatorio in data.relatorios) {
		if (data.relatorios[relatorio]["status"] !== "APROVADO") {
			return false;
		}
	}

	return true;
};

export default Status;
