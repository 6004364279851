import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ModalEmailEmUso({ open, setOpen, email, handleEsqueciMinhaSenha }) {
	const handleClose = () => {
		setOpen(false);
	};

	const [sucesso, setSucesso] = React.useState(false);

	React.useEffect(() => {
		console.log(sucesso);
	}, [sucesso]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{sucesso ? "Sucesso!" : "Email em uso"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{sucesso
						? "Em instantes, você receberá em seu email um link para redefinir sua senha"
						: "Este email já se encontra registrado na plataforma, deseja receber um link para definir uma nova senha?"}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Voltar</Button>
				{sucesso ? null : (
					<Button onClick={() => handleEsqueciMinhaSenha(email, setSucesso)} autoFocus>
						Sim
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
