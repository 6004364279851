import { Button } from "@mui/material";
import { Card, CardContent, CardActions } from "@mui/material";
import notificar from "../notificar";
import { textStyle } from "../../styles/text";

export default function Anexo({ text, description, pendente, file, sx }) {
	if (pendente) {
		return (
			<Card sx={{ flexGrow: 1, ...sx }}>
				<CardContent>
					<p style={textStyle.headerComponent}>{text}</p>
					<p style={textStyle.headerComponent}>{description}</p>
				</CardContent>
				<CardActions>
					<Button style={{ color: "orange" }} disabled>
						PENDENTE
					</Button>
				</CardActions>
			</Card>
		);
	} else
		return (
			<Card sx={{ ...sx, flexGrow: 1 }}>
				<CardContent>
					<p style={textStyle.headerComponent}>{text}</p>
					<p style={textStyle.p}>{description}</p>
				</CardContent>
				<CardActions>
					<Button onClick={() => cacheFileAndOpen(file)}>
						{text === "Planilha Orçamentária" ? "Baixar" : "Visualizar"}
					</Button>
				</CardActions>
			</Card>
		);
}

//salve arquivo em cache e abre o arquivo, parar poder abrir setando o header Authorization
const cacheFileAndOpen = async (file) => {
	let token = await JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;
	let extension = file.split(".").pop();

	if (extension === "pdf") {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				window.open(_url, "_blank").focus();
			})
			.catch((err) => console.error(err));
	} else {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				let link = document.createElement("a");
				link.id = "a";
				link.href = _url;
				link.download = file;
				link.style = "display: none";
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						//view: window,
					})
				);
			})
			.catch((err) => console.error(err));
	}
};
