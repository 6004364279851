import React from "react";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import ModalSolicitacoes from "./modalSolicitacoes.jsx";
import { downloadFile } from "../../../components/downloadFile";
import { textStyle } from "../../../styles/text";

export const Solicitacoes = ({ data, reloadData, setLoading }) => {
	const [open, setOpen] = React.useState(false);
	const [variant, setVariant] = React.useState("bolsa");

	const handleOpen = (variant) => {
		setVariant(variant);
		setOpen(true);
	};

	return (
		<React.Fragment>
			<ModalSolicitacoes
				open={open}
				setOpen={setOpen}
				variant={variant}
				reloadData={reloadData}
				setLoading={setLoading}
				id={data.id}
			/>
			<p style={{ ...textStyle.header }}>Pedidos de Alteração</p>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-evenly",
					flexWrap: "wrap",
					rowGap: 10,
					columnGap: 10,
				}}
			>
				{data.em_andamento ? (
					<React.Fragment>
						<Solicitacao
							header="Ativação de Bolsa"
							text="Iniciar pagamento do bolsista"
							handleOpen={handleOpen}
							variant="bolsa"
						/>
						<Solicitacao
							header="Prorrogação de Prazo"
							text="Solicitar prorrogação do prazo de execução do projeto"
							handleOpen={handleOpen}
							variant="prorrogacao"
						/>
						<Solicitacao
							header="Substituição do Bolsista"
							text="Substituir bolsista do projeto"
							handleOpen={handleOpen}
							variant="substituicao"
						/>
						<Solicitacao
							header="Transposição de Recurso"
							text="Solicitar transposição de recursos do projeto"
							handleOpen={handleOpen}
							variant="transposicao"
						/>
					</React.Fragment>
				) : null}
				<Solicitacao
					header="Errata de Documentos"
					text="Solicitar substituição de documentos do projeto"
					handleOpen={handleOpen}
					variant="errata"
				/>
			</div>
			<Listagem data={data.solicitacoes} />
		</React.Fragment>
	);
};

const Solicitacao = ({ header, text, handleOpen, variant }) => {
	return (
		<Card sx={{ width: "fit-content", flexGrow: 1 }}>
			<CardContent>
				<p style={textStyle.headerComponent}>{header}</p>
				<p style={textStyle.p}>{text}</p>
			</CardContent>
			<CardActions>
				<Button onClick={() => handleOpen(variant)}>Solicitar</Button>
			</CardActions>
		</Card>
	);
};

const Listagem = ({ data }) => {
	let emAberto = [];
	let encerradas = [];
	for (let item in data) {
		if (data[item].ativo) {
			emAberto.push(
				<Card key={Math.random()} sx={{ width: "fit-content", flexGrow: 1 }}>
					<CardContent>
						<p style={textStyle.headerComponent}>{data[item].tipo}</p>
						<p style={textStyle.p}>
							<strong>Comentário do Coordenador: </strong>
							{data[item].comentario}
						</p>
					</CardContent>
					<CardActions>
						<Button disabled style={{ color: "orange" }}>
							PENDENTE
						</Button>
						{data[item].arquivo ? (
							<Button onClick={() => downloadFile(data[item].arquivo)}>
								{data[item].arquivo.split(".").pop() === "pdf" ? "Visualizar" : "Baixar"}
							</Button>
						) : null}
					</CardActions>
				</Card>
			);
		} else {
			encerradas.push(
				<Card key={Math.random()} sx={{ width: "fit-content", flexGrow: 1 }}>
					<CardContent>
						<p style={textStyle.headerComponent}>{data[item].tipo}</p>
						<p style={textStyle.p}>
							<strong>Comentário do Coordenador: </strong>
							{data[item].comentario}
						</p>
						<p style={textStyle.p}>
							<strong>Resposta do Comitê: </strong>
							{data[item].resposta}
						</p>
					</CardContent>
					<CardActions>
						<Button disabled style={{ color: data[item].concedido ? "green" : "red" }}>
							{data[item].concedido ? "CONCEDIDO" : "NEGADO"}
						</Button>
						{data[item].arquivo ? (
							<Button onClick={() => downloadFile(data[item].arquivo)}>
								{data[item].arquivo.split(".").pop() === "pdf" ? "Visualizar" : "Baixar"}
							</Button>
						) : null}
					</CardActions>
				</Card>
			);
		}
	}

	return (
		<div>
			{emAberto.length !== 0 ? <p style={textStyle.header}>Solicitações em aberto</p> : null}
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 10, rowGap: 10 }}>
				{emAberto}
			</div>
			{encerradas.length !== 0 ? <p style={textStyle.header}>Encerradas</p> : null}
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 10, rowGap: 10 }}>
				{encerradas}
			</div>
		</div>
	);
};
