import { AnexoCoordenacao as Anexo } from "../../../components/anexoCoordenacao";

const Anexos = ({ data, reloadData }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "space-between",
			rowGap: 10,
			columnGap: 10,
		}}
	>
		{data.proposta ? (
			<Anexo text="Proposta" file={data.proposta} dbColumn="proposta" id={data.id} reloadData={reloadData} />
		) : null}
		{data.carta_de_anuencia ? (
			<Anexo
				text="Carta de anuência da Instituição"
				file={data.carta_de_anuencia}
				dbColumn="carta_de_anuencia"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}
		{data.docs_dos_bolsistas ? (
			<Anexo
				text="Documentação do Bolsista"
				file={data.docs_dos_bolsistas}
				dbColumn="docs_dos_bolsistas"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}
		{data.doc_adicional ? (
			<Anexo
				text="Documento extra adicionado"
				file={data.doc_adicional}
				dbColumn="doc_adicional"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}
		{data.parecer === "APROVADO" ? (
			<Anexo
				id={data.id}
				text="Cadastro de coordenador"
				pendente={data.cadastro_de_coordenador ? false : true}
				file={data.cadastro_de_coordenador}
				dbColumn="cadastro_de_coordenador"
				reloadData={reloadData}
			/>
		) : null}
		{data.parecer === "APROVADO" && data.categoria === 3 ? (
			<Anexo
				id={data.id}
				text="Protocolo de abertura de evento"
				pendente={data.protocolo_abertura_evento ? false : true}
				file={data.protocolo_abertura_evento}
				dbColumn="protocolo_abertura_evento"
				reloadData={reloadData}
			/>
		) : null}
		{data.parecer === "APROVADO" && (data.categoria === 1 || data.categoria === 2) ? (
			<Anexo
				text="Protocolo de abertura de pesquisa"
				pendente={data.protocolo_abertura_pesquisa ? false : true}
				file={data.protocolo_abertura_pesquisa}
				dbColumn="protocolo_abertura_pesquisa"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}
		{data.parecer === "APROVADO" && data.categoria === 2 ? (
			<Anexo
				text="Formulário de concessão de bolsa"
				pendente={data.formulario_concessao_bolsa ? false : true}
				file={data.formulario_concessao_bolsa}
				dbColumn="formulario_concessao_bolsa"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}

		<Anexo
			text="Planilha Orçamentária"
			pendente={data.plano_de_recursos ? false : true}
			file={data.plano_de_recursos}
			dbColumn="plano_de_recursos"
			excel
			id={data.id}
			reloadData={reloadData}
		/>

		{data.parecer === "APROVADO" && data.categoria === 2 ? (
			<Anexo
				text="Plano de atividades do bolsista"
				pendente={data.plano_de_atividades ? false : true}
				file={data.plano_de_atividades}
				dbColumn="plano_de_atividades"
				id={data.id}
				reloadData={reloadData}
			/>
		) : null}
	</div>
);

export default Anexos;
