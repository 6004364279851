import { Box, Accordion, Button } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ArquivoEnviado from "./arquivo_enviado";
import EnvioRelatorio from "./modal_envio_relatorio";
import ModificarRelatorio from "./modificar_relatorio";

export default function Relatorio({ relatorio, setIsLoading, reloadData }) {
	const statusColorHandler = () => {
		let color = "black";

		switch (relatorio.status) {
			case "PENDENTE":
				color = "orange";
				break;
			case "APROVADO":
				color = "green";
				break;
			case "REPROVADO":
				color = "red";
				break;
			case "AGUARDANDO ANÁLISE":
				color = "orange";
				break;
			default:
				color = "black";
		}

		return color;
	};

	return (
		<>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<p style={{ margin: 0, marginRight: "auto" }}>{relatorio.titulo}</p>
					<p style={{ margin: 0, color: statusColorHandler() }}>{relatorio.status}</p>
				</AccordionSummary>
				<AccordionDetails>
					<Arquivos relatorio={relatorio} setIsLoading={setIsLoading} reloadData={reloadData} />
				</AccordionDetails>
			</Accordion>
		</>
	);
}

function Arquivos({ relatorio, setIsLoading, reloadData }) {
	const [open, setOpen] = React.useState(false);
	let arquivos = [];
	let length = relatorio.arquivos.length - 1;
	for (let index in relatorio.arquivos) {
		arquivos.push(
			<ArquivoEnviado
				key={Math.random()}
				arquivo={relatorio.arquivos[index]}
				relatorio={relatorio}
				length={length}
				index={index}
				status={relatorio.status}
				setIsLoading={setIsLoading}
				reloadData={reloadData}
			/>
		);
	}

	const prazo = new Date(relatorio.prazo).toLocaleDateString("pt-BR", {
		timeZone: "UTC",
	});

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<EnvioRelatorio
				open={open}
				setOpen={setOpen}
				relatorio={relatorio}
				setIsLoading={setIsLoading}
				reloadData={reloadData}
			/>
			<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<p>Prazo de entrega: {prazo}</p>
				<ModificarRelatorio data={relatorio} reloadData={reloadData} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>{arquivos}</Box>
			{relatorio.status === "PENDENTE" || relatorio.status === "REPROVADO" ? (
				<Button
					variant="outlined"
					sx={{ width: "fit-content", marginLeft: "auto", marginTop: "5px" }}
					onClick={() => setOpen(true)}
				>
					{relatorio.status === "REPROVADO" ? "Submeter novo relatório" : "Submeter relatório"}
				</Button>
			) : null}
		</Box>
	);
}
