import React from "react";
import Categoria1 from "./categoria1";
import Categoria2 from "./categoria2";
import Categoria3 from "./categoria3";

export default function EmitirParecer({ data, reloadData }) {
	if (data.categoria === 1) return <Categoria1 data={data} reloadData={reloadData} />;
	else if (data.categoria === 2) return <Categoria2 data={data} reloadData={reloadData} />;
	else if (data.categoria === 3) return <Categoria3 data={data} reloadData={reloadData} />;
}
