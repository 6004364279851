export const textStyle = {
	header: {
		fontSize: window.innerWidth < 1920 ? "22px" : "26px",
		fontWeight: "bold",
		margin: 0,
		marginBottom: "10px",
		marginTop: "10px",
	},
	header2: {
		fontSize: window.innerWidth < 1920 ? "16px" : "22px",
		fontWeight: "bold",
		margin: 0,
	},
	p: {
		fontSize: window.innerWidth < 1920 ? "12px" : "16px",
		margin: 0,
		alignSelf: "center",
	},
	headerNoMargin: {
		fontSize: "22px",
		fontWeight: "bold",
		margin: 0,
	},
	headerComponent: {
		fontSize: window.innerWidth < 1920 ? "20px" : "22px",
		margin: 0,
	},
};
