import * as React from "react";
import {
	DataGrid,
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import * as api from "./api";
import { useNavigate } from "react-router-dom";
import { BarraPesquisa } from "./barraPesquisa";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const columns = [
	{ field: "id", headerName: "ID", width: 70 },
	{
		field: "pendencias",
		headerName: "Pendências",
		width: 130,
	},
	{ field: "protocolo_abertura", headerName: "PA", width: 80 },
	{ field: "resumo_da_proposta", headerName: "Título", width: 300 },
	{
		field: "categoria",
		headerName: "Categoria",
		type: "number",
		width: 80,
	},
	{
		field: "coordenador",
		headerName: "Coordenador",
		width: 180,
	},
	{
		field: "assessor",
		headerName: "Assessor",
		width: 180,
	},
	{
		field: "parecer",
		headerName: "Parecer",
		width: 100,
	},
	{
		field: "instituicao",
		headerName: "Instituição",
		width: 200,
	},
	{
		field: "estado",
		headerName: "Estado",
		width: 130,
	},
];

const StyleDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .pendencias--S": {
		backgroundColor: "#faf884",
		"&:hover": {
			backgroundColor: "#f8b88b",
		},
	},
}));

export default function Tabela({ arquivo }) {
	const [isLoading, setLoading] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	const [query, setQuery] = React.useState("");
	const navigate = useNavigate();

	const loadData = () => {
		setLoading(true);
		if (!arquivo) api.listaTodos(query).then((json) => prepareData(json));
		else api.listaArquivo(query).then((json) => prepareData(json));
	};

	const prepareData = (data) => {
		let rowsCopy = data.map((item) => {
			let pendencias = "N";

			if (!arquivo) {
				for (let index in item.solicitacoes) {
					if (item.solicitacoes[index].ativo) pendencias = "S";
				}
			}

			return {
				id: item.id,
				protocolo_abertura: item.protocolo_abertura[0].pa + "/" + item.protocolo_abertura[0].ano,
				resumo_da_proposta: item.resumo_da_proposta,
				categoria: item.categoria,
				coordenador: item.pesquisador ? item.pesquisador.nome : "Sem coordenador",
				instituicao: item.pesquisador ? item.pesquisador.instituicao : "Sem instituição",
				estado: item.pesquisador ? item.pesquisador.uf : "N/A",
				assessor: item.acessor ? item.acessor.nome : "Sem assessor",
				parecer: item.parecer ? item.parecer : "Sem parecer",
				pendencias,
			};
		});

		setRows([...rowsCopy]);
		setLoading(false);
	};

	React.useEffect(() => {
		loadData();
	}, [arquivo]);

	return (
		<Box sx={{ height: "100%", backgroundColor: "transparent" }}>
			<BarraPesquisa
				header={arquivo ? "Propostas Arquivadas" : "Propostas Ativas"}
				query={query}
				setQuery={setQuery}
				rows={rows}
				setRows={setRows}
				loadData={loadData}
			/>
			<StyleDataGrid
				sx={{ backgroundColor: "white", height: "100vh" }}
				components={{
					Toolbar: CustomToolbar,
				}}
				loading={isLoading}
				rows={rows}
				columns={columns}
				columnVisibilityModel={{
					id: false,
					pendencias: !arquivo,
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: "pendencias", sort: "desc" }],
					},
				}}
				pageSize={15}
				getRowClassName={(params) => `pendencias--${params.row.pendencias}`}
				rowsPerPageOptions={[15]}
				onRowClick={(row) => navigate(`/secretaria/detalhes-proposta/${row.id}`)}
				localeText={{
					toolbarExport: "Exportar",
					toolbarDensity: "Densidade",
					toolbarDensityLabel: "Densidade",
					toolbarDensityCompact: "Compacto",
					toolbarDensityStandard: "Padrão",
					toolbarDensityComfortable: "Confortável",
					toolbarFilters: "Filtros",
					toolbarColumns: "Colunas",
					toolbarExportCSV: "CSV / Compatível com Excel",
					toolbarExportPDF: "PDF",
					toolbarExportPrint: "Imprimir",
					toolbarFilterValue: "valor",
					toolbarFiltersLabel: "Mostrar filtros",
					filterPanelColumns: "Colunas",
					filterPanelOperators: "Operadores",
					filterPanelInputLabel: "Valor",
					filterPanelInputPlaceholder: "Valor do filtro",
					noRowsLabel: "Nenhum projeto encontrado",
					footerRowSelected: (count) => `${count.toLocaleString()} projeto(s) selecionado(s)`,
					footerTotalVisibleRows: (visibleCount, totalCount) =>
						`${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
					footerTotalRows: (totalCount) => `${totalCount.toLocaleString()}`,
					columnMenuSortAsc: "Ordenar crescente",
					columnMenuSortDesc: "Ordenar decrescente",
					columnMenuFilter: "Filtrar",
					columnMenuHideColumn: "Esconder",
					columnMenuUnsort: "Desordenar",
					columnMenuShowColumns: "Mostrar colunas",
					columnMenuPin: "Fixar",
					columnMenuUnpin: "Desafixar",
					columnMenuText: "Menu",
					columnHeaderSortIconLabel: "Ordenar",
					columnsPanelTextFieldLabel: "Pesquisar coluna",
					columnsPanelTextFieldPlaceholder: "Título da coluna",
					columnsPanelDragIconLabel: "Reordenar coluna",
					columnsPanelShowAllButton: "Mostrar tudo",
					columnsPanelHideAllButton: "Esconder tudo",

					noResultsOverlayLabel: "Nenhum resultado encontrado.",
					filterOperatorOnOrAfter: "está em ou depois",
					filterOperatorAfter: "depois",
					filterOperatorBefore: "antes",
					filterOperatorContains: "contém",
					filterOperatorEquals: "é igual a",
					filterOperatorStartsWith: "começa com",
					filterOperatorEndsWith: "termina com",
					filterOperatorIs: "é",
					filterOperatorNot: "não é",
					filterOperatorAfterOrEqual: "é depois ou igual a",
					filterOperatorBeforeOrEqual: "é antes ou igual a",
					filterOperatorIsEmpty: "está vazio",
					filterOperatorIsNotEmpty: "não está vazio",
					filterOperatorIsNot: "não é",
					filterOperatorNotContains: "não contém",
					filterOperatorNotEquals: "não é igual a",
					filterOperatorNotStartsWith: "não começa com",
					filterOperatorNotEndsWith: "não termina com",
					filterOperatorIsBetween: "está entre",
					filterOperatorIsNotBetween: "não está entre",
					filterOperatorIsIn: "está em",
					filterOperatorIsNotIn: "não está em",
					filterOperatorIsAnyOf: "é qualquer um de",
					filterOperatorIsNoneOf: "não é nenhum de",
					filterOperatorIsBlank: "está em branco",
					filterOperatorIsNotBlank: "não está em branco",
					filterOperatorIsTrue: "é verdadeiro",
					filterOperatorIsFalse: "é falso",
					filterOperatorIsOnOrAfter: "está em ou depois",
					filterOperatorIsOnOrBefore: "está em ou antes",
					filterOperatorIsAfter: "está depois",
					filterOperatorIsBefore: "está antes",
					filterOperatorIsOn: "está em",
				}}
			></StyleDataGrid>
		</Box>
	);
}

function CustomToolbar() {
	let today = new Date().toLocaleDateString();

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				csvOptions={{
					fileName: `Listagem de Propostas - ${today}`,
					delimiter: ";",
					utf8WithBom: true,
				}}
			/>
		</GridToolbarContainer>
	);
}
