import create from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";
import notificar from "../components/notificar";

const url = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;

const useUserStore = create(
	persist(
		(set, get) => ({
			name: null,
			id: null,
			token: null,
			login: async (email, password, navigate) => {
				try {
					const response = await axios.post(`${url}/usuario/autenticar`, {
						email,
						password,
					});
					set({
						id: response.data.id,
						acesso: response.data.acesso,
						nome: response.data.nome,
						email: response.data.email,
						token: response.data.token,
					});
					navigate("/pesquisador");
				} catch (err) {
					notificar(err.response.data, "error");
				}
			},
			loginAssessor: async (email, senha, navigate) => {
				try {
					const response = await axios.post(`${url}/acessor/login`, {
						email,
						senha,
					});
					set({
						id: response.data.id,
						acesso: response.data.acesso,
						nome: response.data.nome,
						email: response.data.email,
						token: response.data.token,
					});
					navigate("/assessor");
				} catch (err) {
					notificar(err.response.data, "error");
				}
			},
			loginSecretaria: async (email, senha, navigate) => {
				try {
					const response = await axios.post(`${url}/secretaria/login`, {
						email,
						senha,
					});
					set({
						id: response.data.id,
						acesso: response.data.acesso,
						nome: response.data.nome,
						email: response.data.email,
						token: response.data.token,
					});
					navigate("/secretaria");
				} catch (err) {
					notificar(err.response.data, "error");
				}
			},
			updateAssessor: async (data) => {
				try {
					const response = await axios.put(
						`${url}/acessor/atualizar`,
						{
							...data,
							id: get().id,
							email: get().email,
						},
						{
							headers: {
								Authorization: `Bearer ${get().token}`,
							},
						}
					);
					set({
						nome: response.data.nome,
						email: response.data.email,
					});
					return true;
				} catch (err) {
					notificar(err.response.data, "error");
					return false;
				}
			},
			updateAdmin: async (data) => {
				try {
					const response = await axios.put(
						`${url}/secretaria/update`,
						{
							...data,
						},
						{
							headers: {
								Authorization: `Bearer ${get().token}`,
							},
						}
					);
					set({
						nome: response.data.nome,
						email: response.data.email,
					});
					return true;
				} catch (err) {
					notificar(err.response.data, "error");
					return false;
				}
			},
			logout: (navigate) => {
				set({ user: null, id: null, token: null, email: null });
				navigate("/");
			},
		}),
		{
			name: "user",
			getStorage: () => sessionStorage,
		}
	)
);

export default useUserStore;
