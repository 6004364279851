export async function listaTodos() {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/acessor/listar-todos`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => response.json());
}

export async function registraAssessor(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/acessor/registrar`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body,
	});
}

export async function atualizarAssessor(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/acessor/atualizar`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	});
}
