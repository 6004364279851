import { useState, forwardRef, useEffect } from "react";
import {
	Box,
	ToggleButton,
	ToggleButtonGroup,
	Slide,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
} from "@mui/material";
import * as api from "./api";
import { useParams } from "react-router-dom";
import notificar from "../../../components/notificar";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function IniciarProposta({ data, reloadData }) {
	const [open, setOpen] = useState(false);
	const { idProposta } = useParams();
	const [pickData, setPickData] = useState("today");
	const [date, setDate] = useState("");
	const [disabled, setDisabled] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (pickData === "today") {
			setDisabled(true);
			let dd = new Date().getDate().toLocaleString("en-US", {
				minimumIntegerDigits: 2,
				useGrouping: false,
			});
			let mm = new Date().getMonth() + 1;
			mm = mm.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
			let yyyy = new Date().getFullYear();
			dd = yyyy + "-" + mm + "-" + dd;

			setDate(dd);
		} else setDisabled(false);
	}, [pickData]);

	const handleSubmission = () => {
		api.definirAndamento({
			id: parseInt(idProposta),
			tempo_de_vigencia: data.tempo_de_vigencia,
			date,
		})
			.then(() => reloadData())
			.then(notificar("Vigência Iniciada", "success"))
			.catch((err) => {
				notificar("Erro ao Iniciar Vigência", "error");
				console.error(err);
			});
	};

	if (data.parecer === "APROVADO" && !data.em_andamento)
		return (
			<div style={{ alignSelf: "center" }}>
				<Button variant="outlined" onClick={handleClickOpen}>
					Iniciar Vigência
				</Button>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>{"Selecione o Início da Vigência"}</DialogTitle>
					<DialogContent>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								rowGap: "10px",
							}}
						>
							<ToggleButtonGroup
								exclusive
								value={pickData}
								onChange={(event, newValue) => {
									if (newValue) setPickData(newValue);
								}}
							>
								<ToggleButton value={"today"}>Hoje</ToggleButton>
								<ToggleButton value={"pick"}>Selecionar</ToggleButton>
							</ToggleButtonGroup>

							<input
								type={"date"}
								value={date}
								onChange={(event) => setDate(event.target.value)}
								disabled={disabled}
							/>
							<DialogContentText id="alert-dialog-slide-description">
								A data selecionada aqui será usada como base para calcular os prazos de entrega dos
								relatórios.
							</DialogContentText>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Sair</Button>
						<Button onClick={handleSubmission}>Confirmar</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
}
