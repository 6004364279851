import React from "react";
import { CadastrarCoordenador } from "./cadastrarCoordenador";
import TabelaCoordenadores from "./tabelaCoordenadores";
import { Button } from "@mui/material";
import { ModificarCoordenador } from "./modificarCoordenador";
import { textStyle } from "../../../styles/text";

export default function Usuarios() {
	const [isOpen, setOpen] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [id, setId] = React.useState(null);
	const [displayTable, setDisplayTable] = React.useState(true);

	const handleSelected = (data) => {
		setId(data.id);
		setOpenEdit(true);
	};

	const reloadData = () => {
		setDisplayTable(false);
		setTimeout(() => {
			setDisplayTable(true);
		}, 100);
	};

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<p style={textStyle.header}>Coordenadores</p>
				<Button variant="outlined" onClick={() => setOpen(true)}>
					Cadastrar Coordenador
				</Button>
			</div>

			<CadastrarCoordenador open={isOpen} setOpen={setOpen} reloadData={reloadData} />

			<ModificarCoordenador open={openEdit} setOpen={setOpenEdit} id={id} reloadData={reloadData} />

			{displayTable ? (
				<TabelaCoordenadores isOpen={isOpen} setOpen={setOpen} handleSelected={handleSelected} />
			) : null}
		</div>
	);
}
