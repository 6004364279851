export const drawerStyle = {
	listItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "left",
		width: "100%",
		paddingLeft: "20px",
		columnGap: "10px",
		padding: window.innerWidth < 1920 ? "10px" : "15px",
	},
	p: {
		fontSize: window.innerWidth < 1920 ? "12px" : "16px",
		margin: 0,
	},
	icon: {
		width: "20px",
	},
};

export const drawerWidth = window.innerWidth < 1920 ? 200 : 300;
