import * as React from "react";
import {
	DataGrid,
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import * as api from "./api";
import { Box } from "@mui/material";

const columns = [
	{ field: "id", headerName: "ID", width: 70 },
	{ field: "nome", headerName: "Nome", width: 300 },
	{
		field: "data",
		headerName: "Data de Cadastro",
		width: 160,
	},
	{
		field: "email",
		headerName: "Email",
		width: 250,
	},
	{
		field: "instituicao",
		headerName: "Instituição",
		width: 400,
	},
];

export default function Tabela({ isOpen, setOpen, handleSelected }) {
	const [isLoading, setLoading] = React.useState(true);
	const [rows, setRows] = React.useState([]);

	const loadData = () => {
		setLoading(true);
		api.listaTodos().then((json) => prepareData(json));
	};

	const prepareData = (data) => {
		let rowsCopy = data.map((item) => {
			return {
				id: item.id,
				nome: item.nome,
				data: new Date(item.createdAt).toLocaleDateString("pt-BR", { timeZone: "UTC" }),
				email: item.email,
				instituicao: item.instituicao,
			};
		});

		setRows([...rowsCopy]);
		setLoading(false);
	};

	React.useEffect(() => {
		loadData();
	}, []);

	return (
		<Box sx={{ height: "100%", backgroundColor: "transparent" }}>
			<DataGrid
				sx={{ backgroundColor: "white", height: "100vh" }}
				components={{
					Toolbar: CustomToolbar,
				}}
				disableSelectionOnClick
				loading={isLoading}
				rows={rows}
				columns={columns}
				pageSize={15}
				rowsPerPageOptions={[15]}
				onRowClick={(row) => handleSelected(row.row)}
				localeText={{
					toolbarExport: "Exportar",
					toolbarDensity: "Densidade",
					toolbarDensityLabel: "Densidade",
					toolbarDensityCompact: "Compacto",
					toolbarDensityStandard: "Padrão",
					toolbarDensityComfortable: "Confortável",
					toolbarFilters: "Filtros",
					toolbarColumns: "Colunas",
					toolbarExportCSV: "CSV / Compatível com Excel",
					toolbarExportPDF: "PDF",
					toolbarExportPrint: "Imprimir",
					toolbarFilterValue: "valor",
					toolbarFiltersLabel: "Mostrar filtros",
					filterPanelColumns: "Colunas",
					filterPanelOperators: "Operadores",
					filterPanelInputLabel: "Valor",
					filterPanelInputPlaceholder: "Valor do filtro",
					noRowsLabel: "Nenhum projeto encontrado",
					footerRowSelected: (count) => `${count.toLocaleString()} projeto(s) selecionado(s)`,
					footerTotalVisibleRows: (visibleCount, totalCount) =>
						`${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
					footerTotalRows: (totalCount) => `${totalCount.toLocaleString()}`,
					columnMenuSortAsc: "Ordenar crescente",
					columnMenuSortDesc: "Ordenar decrescente",
					columnMenuFilter: "Filtrar",
					columnMenuHideColumn: "Esconder",
					columnMenuUnsort: "Desordenar",
					columnMenuShowColumns: "Mostrar colunas",
					columnMenuPin: "Fixar",
					columnMenuUnpin: "Desafixar",
					columnMenuText: "Menu",
					columnHeaderSortIconLabel: "Ordenar",
					columnsPanelTextFieldLabel: "Pesquisar coluna",
					columnsPanelTextFieldPlaceholder: "Título da coluna",
					columnsPanelDragIconLabel: "Reordenar coluna",
					columnsPanelShowAllButton: "Mostrar tudo",
					columnsPanelHideAllButton: "Esconder tudo",

					noResultsOverlayLabel: "Nenhum resultado encontrado.",
					filterOperatorOnOrAfter: "está em ou depois",
					filterOperatorAfter: "depois",
					filterOperatorBefore: "antes",
					filterOperatorContains: "contém",
					filterOperatorEquals: "é igual a",
					filterOperatorStartsWith: "começa com",
					filterOperatorEndsWith: "termina com",
					filterOperatorIs: "é",
					filterOperatorNot: "não é",
					filterOperatorAfterOrEqual: "é depois ou igual a",
					filterOperatorBeforeOrEqual: "é antes ou igual a",
					filterOperatorIsEmpty: "está vazio",
					filterOperatorIsNotEmpty: "não está vazio",
					filterOperatorIsNot: "não é",
					filterOperatorNotContains: "não contém",
					filterOperatorNotEquals: "não é igual a",
					filterOperatorNotStartsWith: "não começa com",
					filterOperatorNotEndsWith: "não termina com",
					filterOperatorIsBetween: "está entre",
					filterOperatorIsNotBetween: "não está entre",
					filterOperatorIsIn: "está em",
					filterOperatorIsNotIn: "não está em",
					filterOperatorIsAnyOf: "é qualquer um de",
					filterOperatorIsNoneOf: "não é nenhum de",
					filterOperatorIsBlank: "está em branco",
					filterOperatorIsNotBlank: "não está em branco",
					filterOperatorIsTrue: "é verdadeiro",
					filterOperatorIsFalse: "é falso",
					filterOperatorIsOnOrAfter: "está em ou depois",
					filterOperatorIsOnOrBefore: "está em ou antes",
					filterOperatorIsAfter: "está depois",
					filterOperatorIsBefore: "está antes",
					filterOperatorIsOn: "está em",
				}}
			/>
		</Box>
	);
}

function CustomToolbar() {
	let today = new Date().toLocaleDateString();

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarExport
				csvOptions={{
					fileName: `Coordenadores - ${today}`,
					delimiter: ";",
					utf8WithBom: true,
				}}
			/>
		</GridToolbarContainer>
	);
}
