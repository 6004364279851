import { Warning, CheckBox } from "@mui/icons-material";
import { textStyle } from "../../../styles/text";

const Status = ({ dados }) => {
	if (dados.parecer === "APROVADO" || dados.parecer === "REPROVADO")
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					width: "fit-content",
					height: "fit-content",
					flexWrap: "wrap",
				}}
			>
				{dados.parecer === "REPROVADO" ? (
					<Warning sx={{ color: "orange", marginRight: "5px" }} />
				) : (
					<CheckBox sx={{ color: "green", marginRight: "5px" }} />
				)}

				<p style={textStyle.p}>
					{dados.parecer === "APROVADO" ? "Proposta Aprovada" : "Proposta Não Recomendada"}
				</p>
			</div>
		);
	else
		return (
			<div
				style={{
					width: "fit-content",
					display: "flex",
					height: "fit-content",
					alignItems: "center",
					borderRadius: "5px",
					marginLeft: "auto",
				}}
			>
				<Warning sx={{ color: "orange", marginRight: "5px", fontSize: "20px" }} />
				<p style={textStyle.p}>Aguardando análise</p>
			</div>
		);
};

export default Status;
