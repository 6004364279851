import { useState, forwardRef } from "react";
import {
	Slide,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	TextField,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function ResponderSolicitacao({ item, open, setOpen, enviar }) {
	const [resposta, setResposta] = useState("");

	const handleClose = () => {
		setOpen(false);
	};

	if (open)
		return (
			<div>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle>Responder Solicitação do Coordenador</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">{item.comentario}</DialogContentText>
						<TextField
							sx={{ marginTop: "10px" }}
							multiline
							label="Resposta"
							fullWidth
							value={resposta}
							onChange={(event) => setResposta(event.target.value)}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} sx={{ marginRight: "auto" }}>
							Voltar
						</Button>
						<Button
							sx={{ color: "red" }}
							onClick={() => {
								if (resposta) enviar(item.id, resposta, false);
								else alert("É necessário escrever uma resposta antes de reprovar a solicitação.");
							}}
						>
							Reprovar
						</Button>
						<Button
							onClick={() => {
								if (resposta) enviar(item.id, resposta, true);
								else alert("É necessário escrever uma resposta antes de aprovar a solicitação.");
							}}
						>
							Aprovar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
}
