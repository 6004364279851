import * as React from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Typography,
	Toolbar,
	ListItem,
	List,
	Drawer,
	Divider,
	CssBaseline,
	Box,
	AppBar,
	IconButton,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { Source, Settings, Group, Person, AccountBox, Inventory } from "@mui/icons-material";
import logo from "../../assets/logo.png";
import { Perfil } from "../../components/PerfilUsuario";
import { Footer } from "../../components/Footer/index";
import { drawerStyle, drawerWidth } from "../../styles/drawer";

function ResponsiveDrawer(props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<div>
			<Toolbar
				sx={{
					backgroundColor: "white",
					justifyContent: "center",
				}}
			>
				<img src={logo} alt="Logo Fundação Agrisus" style={{ height: "50px" }} />
			</Toolbar>

			<Perfil />

			<List sx={{ padding: 0 }}>
				<ListItem
					key={1}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<Source sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>PROPOSTAS</p>
					</div>
				</ListItem>
				<Divider />
				<ListItem
					key={2}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria/propostas-arquivadas")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<Inventory sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>ARQUIVO</p>
					</div>
				</ListItem>
				<Divider />
				<ListItem
					key={3}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria/acessores")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<Group sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>COMITÊ CIENTÍFICO</p>
					</div>
				</ListItem>
				<Divider />
				<ListItem
					key={4}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria/coordenadores")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<Person sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>COORDENADORES</p>
					</div>
				</ListItem>
				<Divider />
				<ListItem
					key={5}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria/conta")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<AccountBox sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>MINHA CONTA</p>
					</div>
				</ListItem>
				<Divider />
				<ListItem
					key={6}
					sx={{
						justifyContent: "center",
						transition: "background-color 0.2s",
						":hover": {
							backgroundColor: "rgb(245,245,245)",
						},
					}}
					onClick={() => navigate("/secretaria/configuracoes")}
					disablePadding
				>
					<div style={drawerStyle.listItem}>
						<Settings sx={drawerStyle.icon} />
						<p style={drawerStyle.p}>CONFIGURAÇÕES</p>
					</div>
				</ListItem>
				<Divider />
			</List>
			<Footer />
		</div>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					backgroundColor: "rgb(51, 204, 128)",
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: "none" } }}
					>
						<MenuIcon sx={drawerStyle.icon} />
					</IconButton>
					<Typography variant="h6" noWrap component="div">
						Área Administrativa - Coordenação Executiva
					</Typography>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					backgroundColor: "rgb(245,245,250)",
					minHeight: "100vh",
					overflow: "hidden",
					padding: "20px",
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
}

ResponsiveDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default ResponsiveDrawer;
