import { Divider, Box, Button } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../stores/user";

export const Perfil = () => {
	const user = JSON.parse(sessionStorage.getItem("user"));
	const navigate = useNavigate();
	const logout = useUserStore((state) => state.logout);

	return (
		<>
			<Divider />
			<Box
				sx={{
					marginBottom: "10px",
					marginTop: "10px",
					backgroundColor: "transparent",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
				}}
			>
				<AccountCircle sx={{ fontSize: window.innerWidth < 1920 ? "50px" : "100px" }} />

				<p style={{ margin: 0, fontSize: "14px" }}>{user.state.nome}</p>
				<p style={{ margin: 0, fontSize: "14px" }}>{user.state.email}</p>

				<Button
					sx={{
						color: "red",
						fontSize: "12px",
						padding: 0,
					}}
					onClick={() => logout(navigate)}
				>
					SAIR <Logout sx={{ height: "16px", margin: 0 }} />
				</Button>
			</Box>
			<Divider />
		</>
	);
};
