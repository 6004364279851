import { Box, TextField, Button, InputAdornment, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React from "react";
import notificar from "../../../components/notificar";

const sx = {
	backgroundColor: "white",
	flexGrow: 1,
};

const handleCategoria = (categoria) => {
	switch (categoria) {
		case 1:
			return "Projeto de Pesquisa sem Bolsa de Iniciação Científica";
		case 2:
			return "Projeto de Pesquisa com Bolsa de Iniciação Científica";
		case 3:
			return "Financiamento de Eventos Técnicos / Demonstrações a campo";
		default:
			return "Outro";
	}
};

const Detalhes = ({ data, setIsLoading, reloadData }) => {
	const variant = "outlined";
	const disabled = false;
	const [resumo_da_proposta, setResumo] = React.useState(data.resumo_da_proposta);
	const [id_fealq, setIdFealq] = React.useState(data.id_fealq);
	const [edital, setEdital] = React.useState(data.edital);
	const [categoria, setCategoria] = React.useState(data.categoria);
	const [valor_do_projeto, setValor] = React.useState(data.valor_do_projeto);
	const [coordenador, setCoordenador] = React.useState(data.pesquisador.nome);
	const [razao_social_financiadora, setRazaoSocialFinanciadora] = React.useState(data.razao_social_financiadora);
	const [valor_financiamento_externo, setValorFinanciamentoExterno] = React.useState(
		data.valor_financiamento_externo
	);
	const [inicio_evento, setInicioEvento] = React.useState(data.inicio_evento);
	const [termino_evento, setTerminoEvento] = React.useState(data.termino_evento);
	const [tempo_de_vigencia, setVigencia] = React.useState(data.tempo_de_vigencia);

	const handleSubmission = async () => {
		setIsLoading(true);
		let body = {
			id: data.id,
			resumo_da_proposta,
			id_fealq,
			edital,
			categoria,
			valor_do_projeto,
			valor_financiamento_externo,
			razao_social_financiadora,
			inicio_evento,
			termino_evento,
			tempo_de_vigencia,
		};

		let user = JSON.parse(sessionStorage.getItem("user"));
		let token = user.state.token;

		try {
			await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/editar`, {
				method: "PUT",
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			});
			notificar("Proposta editada com sucesso!", "success");
			reloadData();
		} catch (error) {
			notificar("Erro ao editar proposta!", "error");
			console.log(error);
			setIsLoading(false);
		}
	};

	const handleCurrency = (event, setState) => {
		let value = event.target.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		setState(value);
	};

	return (
		<Box
			component="form"
			sx={{
				backgroundColor: "transparent",
				justifyContent: "space-evenly",
				display: "flex",
				flexWrap: "wrap",
				rowGap: "20px",
				marginTop: "50px",
				columnGap: 2,
				flexGrow: 1,
			}}
		>
			<TextField
				value={resumo_da_proposta}
				onChange={(event) => setResumo(event.target.value)}
				label="Titulo da Proposta"
				multiline
				fullWidth
				disabled={disabled}
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={coordenador}
				onChange={(event) => setCoordenador(event.target.value)}
				label="Coordenador"
				multiline
				fullWidth
				disabled={true}
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.pesquisador.instituicao}
				label="Instituição"
				multiline
				fullWidth
				disabled={true}
				sx={sx}
				variant={variant}
			/>
			<TextField disabled={true} value={data.id} label="ID da Proposta" sx={sx} variant={variant} />
			<TextField
				disabled
				value={data.protocolo_abertura[0].pa + "/" + data.protocolo_abertura[0].ano}
				label="Protocolo de Abertura"
				sx={sx}
				variant={variant}
			/>
			<TextField
				disabled={disabled}
				value={id_fealq}
				onChange={(event) => setIdFealq(event.target.value)}
				label="ID Fealq"
				sx={sx}
				variant={variant}
			/>
			<TextField
				disabled={disabled}
				value={edital}
				onChange={(event) => setEdital(event.target.value)}
				label="Edital"
				sx={sx}
				variant={variant}
			/>
			<TextField
				disabled={false}
				value={categoria}
				onChange={(event) => {
					let categoria = event.target.value;

					if (categoria < 1 || categoria > 3) setCategoria(data.categoria);
					else setCategoria(parseInt(categoria));
				}}
				label="Categoria"
				sx={sx}
				type="number"
				variant={variant}
			/>
			{data.categoria === 3 ? (
				<>
					<TextField
						value={inicio_evento}
						onChange={(event) => setInicioEvento(event.target.value)}
						label="Início do Evento"
						sx={sx}
						variant={variant}
					/>
					<TextField
						value={termino_evento}
						onChange={(event) => setTerminoEvento(event.target.value)}
						label="Término do Evento"
						sx={sx}
						variant={variant}
					/>
				</>
			) : null}
			<TextField
				disabled={true}
				fullWidth
				value={handleCategoria(categoria)}
				label="Descrição da categoria"
				sx={sx}
				variant={variant}
			/>
			<TextField
				disabled={disabled}
				value={valor_do_projeto}
				onChange={(event) => handleCurrency(event, setValor)}
				label="Valor Solicitado"
				type="text"
				sx={sx}
				variant={variant}
				InputProps={{
					startAdornment: <InputAdornment position="start">R$</InputAdornment>,
				}}
			/>
			{data.valor_financiamento_externo !== "0" ? (
				<div
					style={{
						flexGrow: 1,
						width: "100%",
						display: "flex",
						columnGap: "15px",
						flexWrap: "wrap",
						rowGap: "20px",
					}}
				>
					<TextField
						disabled={disabled}
						value={razao_social_financiadora}
						onChange={(event) => setRazaoSocialFinanciadora(event.target.value)}
						label="Razão Social da Entidade Financiadora Externa"
						sx={sx}
						variant={variant}
					/>
					<TextField
						disabled={disabled}
						value={valor_financiamento_externo}
						onChange={(event) => handleCurrency(event, setValorFinanciamentoExterno)}
						label="Valor Financiado"
						sx={sx}
						variant={variant}
						InputProps={{
							startAdornment: <InputAdornment position="start">R$</InputAdornment>,
						}}
					/>
				</div>
			) : null}

			<FormControl>
				<InputLabel>Vigência</InputLabel>
				<Select
					sx={{ backgroundColor: "white" }}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={tempo_de_vigencia}
					label="Vigência"
					onChange={(event) => setVigencia(event.target.value)}
				>
					<MenuItem value={"4 meses"}>4 meses</MenuItem>
					<MenuItem value={"12 meses"}>12 meses</MenuItem>
					<MenuItem value={"18 meses"}>18 meses</MenuItem>
					<MenuItem value={"24 meses"}>24 meses</MenuItem>
					<MenuItem value={"36 meses"}>36 meses</MenuItem>
				</Select>
			</FormControl>

			<Button variant={variant} sx={{ height: "fit-content", alignSelf: "center" }} onClick={handleSubmission}>
				Salvar
			</Button>
		</Box>
	);
};

export default Detalhes;
