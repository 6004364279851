//import styles from "./acompanhamento.module.css";
import Anexo from "../../../components/anexoDownload";

const Anexos = ({ data }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "space-between",
			rowGap: 10,
			columnGap: 10,
		}}
	>
		{data.proposta ? <Anexo text="Proposta" file={data.proposta} /> : null}

		{data.carta_de_anuencia ? (
			<Anexo text="Carta de anuência da Instituição" file={data.carta_de_anuencia} />
		) : null}

		{data.docs_dos_bolsistas ? <Anexo text="Documentação do Bolsista" file={data.docs_dos_bolsistas} /> : null}

		{data.plano_de_recursos ? <Anexo text="Planilha Orçamentária" file={data.plano_de_recursos} /> : null}

		{data.doc_adicional ? <Anexo text="Documento extra adicionado" file={data.doc_adicional} /> : null}

		{data.parecer === "APROVADO" ? (
			<Anexo
				text="Cadastro de coordenador"
				pendente={data.cadastro_de_coordenador ? false : true}
				file={data.cadastro_de_coordenador}
			/>
		) : null}

		{data.parecer === "APROVADO" && data.categoria === 3 ? (
			<Anexo
				text="Protocolo de abertura de evento"
				pendente={data.protocolo_abertura_evento ? false : true}
				file={data.protocolo_abertura_evento}
			/>
		) : null}

		{data.parecer === "APROVADO" && (data.categoria === 1 || data.categoria === 2) ? (
			<Anexo
				text="Protocolo de abertura de pesquisa"
				pendente={data.protocolo_abertura_pesquisa ? false : true}
				file={data.protocolo_abertura_pesquisa}
			/>
		) : null}

		{data.parecer === "APROVADO" && data.categoria === 2 ? (
			<Anexo
				text="Formulário de concessão de bolsa"
				pendente={data.formulario_concessao_bolsa ? false : true}
				file={data.formulario_concessao_bolsa}
			/>
		) : null}

		{data.parecer === "APROVADO" && !data.plano_de_recursos ? (
			<Anexo
				text="Planilha Orçamentária"
				pendente={data.plano_de_recursos ? false : true}
				file={data.plano_de_recursos}
			/>
		) : null}

		{data.parecer === "APROVADO" && data.categoria === 2 ? (
			<Anexo
				text="Plano de atividades do bolsista"
				pendente={data.plano_de_atividades ? false : true}
				file={data.plano_de_atividades}
			/>
		) : null}
	</div>
);

export default Anexos;
