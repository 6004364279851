import { useState } from "react";
import { Card, CardContent, CardActions, Button } from "@mui/material";
import { ResponderSolicitacao } from "./modal_resposta_solicitacao";
import { downloadFile } from "../../../components/downloadFile";
import notificar from "../../../components/notificar";
import { textStyle } from "../../../styles/text";

export const Solicitacoes = ({ data, reloadData }) => {
	const [open, setOpen] = useState(false);
	const [resposta, setResposta] = useState("");
	const [item, setItem] = useState(null);

	const handleApi = async (idSolicitacao, resposta, concedido) => {
		const user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.state.token;

		let response = await fetch(
			`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/modificar-solicitacao`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ idProposta: data.id, idSolicitacao: idSolicitacao, concedido, resposta }),
			}
		).catch((err) => console.error(err));

		if (response.status === 200) {
			notificar("Solicitação alterada com sucesso!", "success");
			reloadData();
		} else {
			notificar("Erro ao alterar solicitação!", "error");
		}
	};

	let emAberto = [];
	let encerradas = [];
	for (let index in data.solicitacoes) {
		if (data.solicitacoes[index].ativo) {
			let item = data.solicitacoes[index];
			emAberto.push(
				<Card key={Math.random()} sx={{ flexGrow: 1 }}>
					<CardContent>
						<p style={textStyle.headerComponent}>{item.tipo}</p>
						<p style={textStyle.p}>
							<strong>Comentário do Coordenador:</strong> {item.comentario}
						</p>
					</CardContent>
					<CardActions>
						<Button
							onClick={() => {
								setItem(item);
								setOpen(true);
							}}
						>
							Responder
						</Button>
						{item.arquivo ? (
							<Button onClick={() => downloadFile(item.arquivo)}>
								{item.arquivo.split(".").pop() === "pdf" ? "Visualizar" : "Baixar"} Anexo
							</Button>
						) : null}
					</CardActions>
				</Card>
			);
		} else {
			let item = data.solicitacoes[index];
			encerradas.push(
				<Card key={Math.random()} sx={{ width: "fit-content", flexGrow: 1 }}>
					<CardContent>
						<p style={textStyle.headerComponent}>{item.tipo}</p>
						<p style={textStyle.p}>
							<strong>Comentário do Coordenador:</strong> {item.comentario}
						</p>

						<p style={textStyle.p}>
							<strong>Resposta do Comitê:</strong> {item.resposta}
						</p>
					</CardContent>
					<CardActions>
						<Button disabled style={{ color: item.concedido ? "green" : "red" }}>
							{item.concedido ? "CONCEDIDO" : "NEGADO"}
						</Button>
						{item.arquivo ? (
							<Button onClick={() => downloadFile(item.arquivo)}>
								{item.arquivo.split(".").pop() === "pdf" ? "Visualizar" : "Baixar"} Anexo
							</Button>
						) : null}
					</CardActions>
				</Card>
			);
		}
	}
	return (
		<div>
			<ResponderSolicitacao
				item={item}
				open={open}
				setOpen={setOpen}
				resposta={resposta}
				setResposta={setResposta}
				enviar={handleApi}
			/>
			{emAberto.length !== 0 ? <p style={textStyle.header}>Solicitações em aberto</p> : null}
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 10, rowGap: 10 }}>
				{emAberto}
				{emAberto.length === 0 && encerradas.length === 0 ? (
					<h4 style={{ margin: "auto", marginTop: "20px" }}>Nenhuma solicitação no momento</h4>
				) : null}
			</div>
			{encerradas.length !== 0 ? <p style={textStyle.header}>Encerradas</p> : null}
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 10, rowGap: 10 }}>
				{encerradas}
			</div>
		</div>
	);
};
