import { Box, InputAdornment, TextField } from "@mui/material";

const Parecer = ({ data }) => {
	const sx = {
		backgroundColor: "white",
	};
	let parecer_dados = JSON.parse(data.parecer_dados);

	if (data.parecer)
		return (
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
				<h1>Parecer do Comitê Científico</h1>
				<TextField label="Pontuação" value={parecer_dados.media} variant="outlined" sx={sx} />
				{data.parecer === "APROVADO" ? (
					<>
						<TextField
							label="Orçamento Aprovado"
							value={parecer_dados.sugerirOrcamento ? parecer_dados.valorSugerido : data.valor_do_projeto}
							variant="outlined"
							sx={sx}
							InputProps={{
								startAdornment: <InputAdornment position="start">R$</InputAdornment>,
							}}
						/>
						<TextField
							label="Motivo"
							value={parecer_dados.comentario}
							fullWidth
							variant="outlined"
							sx={sx}
						/>
					</>
				) : null}
				<TextField
					label="Deliberação do Assessor"
					value={data.parecer_deliberacao}
					fullWidth
					variant="outlined"
					sx={sx}
				/>
			</Box>
		);
};

export default Parecer;
