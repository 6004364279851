import { Box, Accordion, Button } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ArquivoEnviado from "./arquivo_enviado";
import EnvioRelatorio from "./modal_envio_relatorio";

export default function Relatorio({ data, setIsLoading, reloadData }) {
	const statusColorHandler = () => {
		let color = "black";

		switch (data.status) {
			case "PENDENTE":
				color = "orange";
				break;
			case "APROVADO":
				color = "green";
				break;
			case "REPROVADO":
				color = "red";
				break;
			case "AGUARDANDO ANÁLISE":
				color = "orange";
				break;
			default:
				color = "black";
		}

		return color;
	};

	return (
		<>
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<p style={{ margin: 0, marginRight: "auto" }}>{data.titulo}</p>
					<p style={{ margin: 0, color: statusColorHandler() }}>{data.status}</p>
				</AccordionSummary>
				<AccordionDetails>
					<Relatorios data={data} setIsLoading={setIsLoading} reloadData={reloadData} />
				</AccordionDetails>
			</Accordion>
		</>
	);
}

function Relatorios({ data, setIsLoading, reloadData }) {
	const [open, setOpen] = React.useState(false);
	let arquivos = [];
	let length = data.arquivos.length - 1;
	for (let index in data.arquivos) {
		arquivos.push(
			<ArquivoEnviado
				arquivo={data.arquivos[index]}
				length={length}
				index={index}
				status={data.status}
				key={Math.random()}
			/>
		);
	}

	const prazo = new Date(data.prazo).toLocaleDateString("pt-BR", {
		timeZone: "UTC",
	});

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<EnvioRelatorio
				open={open}
				setOpen={setOpen}
				data={data}
				setIsLoading={setIsLoading}
				reloadData={reloadData}
			/>
			<p>Prazo de entrega: {prazo}</p>
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>{arquivos}</Box>
			{data.status === "PENDENTE" || data.status === "REPROVADO" ? (
				<Button
					variant="outlined"
					sx={{ width: "fit-content", marginLeft: "auto", marginTop: "5px" }}
					onClick={() => setOpen(true)}
				>
					{data.status === "REPROVADO" ? "Submeter novo relatório" : "Submeter relatório"}
				</Button>
			) : null}
		</Box>
	);
}
