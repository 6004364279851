import { Tab, Tabs, Box } from "@mui/material";
import { useEffect, useState } from "react";
import * as api from "./api";
import { useParams } from "react-router-dom";
import Status from "./status";
import AtribuirAcessor from "./atribuir-assessor";
import Detalhes from "./detalhes";
import Anexos from "./anexos";
import { Solicitacoes } from "./solicitacoes";
import { Loader } from "../../../components/Loader";
import EmitirParecer from "../EmitirParecer";
import DefinirRecomendacao from "./definir-recomendacao";
import { Relatorios } from "./relatorios";
import { textStyle } from "../../../styles/text";

export default function DetalhesProposta() {
	const [data, setData] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const { idProposta } = useParams();
	const [tab, setTab] = useState(0);

	useEffect(() => loadData(), []);

	const loadData = () => {
		setIsLoading(true);
		api.detalhesProposta(idProposta).then((json) => {
			if (json !== "ERROR") {
				setData(json);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		});
	};

	if (!isLoading)
		return (
			<>
				{!data ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<h2>Nenhum projeto enviado</h2>
					</div>
				) : (
					<div>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								columnGap: "20px",
								flexWrap: "wrap",
								rowGap: "10px",
							}}
						>
							<AtribuirAcessor
								open={open}
								setOpen={setOpen}
								textoBotao={data.id_acessor === 0 ? "Atribuir" : "Alterar"}
								idAssessor={data.id_acessor}
								reloadData={loadData}
							/>
							<DefinirRecomendacao data={data} reloadData={loadData} />
							<Status dados={data} />
						</Box>

						<Tabs
							variant="scrollable"
							value={tab}
							onChange={(event, newValue) => setTab(newValue)}
							sx={{ height: "fit-content", color: "white", marginTop: "2px" }}
						>
							<Tab label="Documentação" />
							<Tab label="Relatórios" />
							<Tab label="Alterações Solicitadas" />
							<Tab label="Parecer do Comitê" />
						</Tabs>
						{tab === 0 ? <p style={textStyle.header}>Documentação</p> : null}
						{tab === 1 ? (
							<Relatorios
								data={data}
								reloadData={loadData}
								isLoading={isLoading}
								setIsLoading={setIsLoading}
							/>
						) : null}

						{tab === 0 ? <Anexos data={data} reloadData={loadData} /> : null}
						{tab === 2 ? <Solicitacoes data={data} reloadData={loadData} /> : null}
						{tab === 0 ? <Detalhes data={data} setIsLoading={setIsLoading} reloadData={loadData} /> : null}
						{tab === 3 ? <EmitirParecer reloadData={loadData} data={data} /> : null}
					</div>
				)}
			</>
		);
	else return <Loader />;
}
