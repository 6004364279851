import { Box, Button, Card, CardContent, CardActions, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DoubleArrow } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../stores/user";
import plant from "../../../assets/plant.png";
import { textStyle } from "../../../styles/text";

export function Submissao() {
	const [mensagemPermissao, setMensagemPermissao] = useState("");
	const [permitirSubmissao, setPermitirSubmissao] = useState(false);
	const [loading, setLoading] = useState(true);
	const user = useUserStore((state) => state);

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/consultar-permissao-de-submissao?coordenador=${user.id}`,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			)
			.then((response) => {
				if (response.data.permitido === true) {
					setPermitirSubmissao(true);
					setLoading(false);
				} else if (response.data.permitido === false) {
					setMensagemPermissao(response.data.mensagem);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	if (!loading && permitirSubmissao)
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
				<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
					<p style={textStyle.header}>Submissão de Proposta</p>
					<Button onClick={downloadEdital}>Download do Edital</Button>
				</Box>
				<Outlet />
			</Box>
		);
	else if (!loading && !permitirSubmissao)
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					backgroundColor: "transparent",
					height: "100%",
				}}
			>
				<img src={plant} height="100px" alt="planta verde" />
				<p style={textStyle.header2}>{mensagemPermissao}</p>
			</div>
		);
	else {
		return (
			<Box sx={{ height: "100%", display: "flex" }}>
				<CircularProgress sx={{ margin: "auto" }} />
			</Box>
		);
	}
}

export const SelecaoCategoria = ({ set_categoria, set_pagina }) => {
	return (
		<Box style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", rowGap: 10 }}>
			<CardCategoria categoria={1} setPagina={set_pagina} />
			<CardCategoria categoria={2} setPagina={set_pagina} />
			<CardCategoria categoria={3} setPagina={set_pagina} />
		</Box>
	);
};

const CardCategoria = ({ categoria, setPagina }) => {
	const navigate = useNavigate();

	return (
		<Box>
			<Card>
				<CardContent>
					<Typography variant="h6" component="h2">
						Categoria {categoria}
					</Typography>
					{categoria === 1 ? (
						<>
							<Typography variant="body2" component="p">
								Projeto de Pesquisa sem Bolsa de Iniciação Científica
							</Typography>
							<Typography variant="body2" component="p">
								<strong>Valor financiado: </strong> Limite R$ 50.000,00 (cinquenta mil reais)
							</Typography>
						</>
					) : null}
					{categoria === 2 ? (
						<>
							<Typography variant="body2" component="p">
								Projeto de Pesquisa com Bolsa de Iniciação Científica
							</Typography>
							<Typography variant="body2" component="p">
								Nesta categoria as solicitações de Bolsa de Iniciação Científica (IC) estão limitadas a
								1 (uma) bolsa por projeto, por ano.
							</Typography>
							<Typography variant="body2" component="p">
								<strong>Valor financiado: </strong> Limite R$ 50.000,00 (cinquente mil reais), já
								incluindo o valor total a ser destinado para o pagamento da Bolsa de Iniciação
								Científica, definida em R$500,00 mensais
							</Typography>
						</>
					) : null}
					{categoria === 3 ? (
						<>
							<Typography variant="body2" component="p">
								Financiamento de Eventos Técnicos / Demonstrações a campo
							</Typography>
							<Typography variant="body2" component="p">
								<strong>Valor financiado: </strong> Limite R$ 15.000,00 (quinze mil reais)
							</Typography>
						</>
					) : null}
				</CardContent>
				<CardActions>
					<Button
						onClick={() => navigate(`categoria-${categoria}`)}
						style={{ backgroundColor: "black", color: "white", marginLeft: "auto" }}
					>
						Selecionar <DoubleArrow />
					</Button>
				</CardActions>
			</Card>
		</Box>
	);
};

const downloadEdital = async () => {
	let token = await JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=edital.pdf`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			const _url = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.id = "a";
			link.href = _url;
			link.download = "edital.pdf";
			link.style = "display: none";
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					//view: window,
				})
			);
		})
		.catch((err) => console.error(err));
};
