import React from "react";
import styles from "./documentosPendentes.module.css";
import { AnexoPendente } from "../../../components/anexoPendente";
import { textStyle } from "../../../styles/text";

const DocumentosPendentes = ({ data, reloadData }) => {
	const [protocoloAberturaEvento, setProtocoloAberturaEvento] = React.useState(null);
	const [cadastroCoordenador, setCadastroCoordenador] = React.useState(null);
	const [protocoloAberturaPesquisa, setProtocoloAberturaPesquisa] = React.useState(null);
	const [formularioConcessaoBolsa, setFormularioConcessaoBolsa] = React.useState(null);
	const [planoAtividades, setPlanoAtividades] = React.useState(null);

	if (
		!data.cadastro_de_coordenador ||
		(!data.protocolo_abertura_evento && data.categoria === 3) ||
		(!data.protocolo_abertura_pesquisa && (data.categoria === 1 || data.categoria === 2)) ||
		(!data.formulario_concessao_bolsa && data.categoria === 2) ||
		(!data.plano_de_atividades && data.categoria === 2) ||
		(data.parecer === "APROVADO" && !data.plano_de_recursos)
	) {
		return (
			<div id={styles.outerDiv}>
				<p style={{ ...textStyle.header }}>Documentos Pendentes</p>
				<div style={{ display: "flex", flexWrap: "wrap", rowGap: 10, columnGap: 10 }}>
					{!data.cadastro_de_coordenador ? (
						<AnexoPendente
							value={cadastroCoordenador}
							setValue={setCadastroCoordenador}
							text={"Cadastro de coordenador"}
							dbColumn={"cadastro_de_coordenador.pdf"}
							data={data}
							reloadData={reloadData}
						/>
					) : null}

					{!data.protocolo_abertura_evento && data.categoria === 3 ? (
						<AnexoPendente
							value={protocoloAberturaEvento}
							setValue={setProtocoloAberturaEvento}
							text={"Protocolo de abertura de evento"}
							dbColumn={"protocolo_abertura_evento.pdf"}
							data={data}
							reloadData={reloadData}
						/>
					) : null}

					{!data.protocolo_abertura_pesquisa && (data.categoria === 1 || data.categoria === 2) ? (
						<AnexoPendente
							value={protocoloAberturaPesquisa}
							setValue={setProtocoloAberturaPesquisa}
							text={"Protocolo de abertura de pesquisa"}
							dbColumn={"protocolo_abertura_pesquisa.pdf"}
							data={data}
							reloadData={reloadData}
						/>
					) : null}

					{data.parecer === "APROVADO" && !data.plano_de_recursos ? (
						<AnexoPendente
							value={formularioConcessaoBolsa}
							setValue={setFormularioConcessaoBolsa}
							text={"Planilha Orçamentária"}
							excel
							dbColumn={
								data.categoria === 1
									? "planilha_orcamentaria_cat1.xlsx"
									: data.categoria === 2
									? "planilha_orcamentaria_cat2.xlsx"
									: "planilha_orcamentaria_cat3.xlsx"
							}
							data={data}
							reloadData={reloadData}
						/>
					) : null}

					{!data.formulario_concessao_bolsa && data.categoria === 2 ? (
						<AnexoPendente
							value={formularioConcessaoBolsa}
							setValue={setFormularioConcessaoBolsa}
							text={"Formulário de concessão de bolsa"}
							dbColumn={"formulario_concessao_bolsa.pdf"}
							data={data}
							reloadData={reloadData}
						/>
					) : null}

					{!data.plano_de_atividades && data.categoria === 2 ? (
						<AnexoPendente
							value={planoAtividades}
							setValue={setPlanoAtividades}
							text={"Plano de atividades do bolsista"}
							dbColumn={"plano_de_atividades.pdf"}
							data={data}
							reloadData={reloadData}
						/>
					) : null}
				</div>
			</div>
		);
	}
};

export default DocumentosPendentes;
