export async function detalhesProposta(id_proposta) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/detalhes?id=${id_proposta}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	).then((response) => response.json());
}

export async function listarAcessores(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/acessor/listar-todos`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => response.json());
}

export async function designarAcessor(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/designar-acessor`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	});
}

export async function definirIdAgrisus(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/definir-id-agrisus`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		}
	).then((response) => response.json());
}

export async function definirIdFealq(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/definir-id-fealq`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	}).then((response) => response.json());
}

export async function definirEdital(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/definir-edital`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	}).then((response) => response.json());
}

export async function arquivarProposta(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/arquivar`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	}).then((response) => response.json());
}

export async function reativarProposta(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/reativar`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	}).then((response) => response.json());
}

export async function definirAprovacao(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/definir-aprovacao`,
		{
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		}
	).then((response) => response.json());
}

export async function definirAndamento(body) {
	let token = JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/definir-andamento`,
		{
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		}
	).then((response) => response.json());
}
