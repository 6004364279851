import { Button, TextField } from "@mui/material";
import { textStyle } from "../../../styles/text";
import { useEffect } from "react";

export const BarraPesquisa = ({ query, setQuery, header, rows, setRows, loadData }) => {
	useEffect(() => {
		if (query.length > 0) {
			const filteredRows = rows.filter((row) => {
				return row.protocolo_abertura.includes(query);
			});
			setRows(filteredRows);
		} else {
			loadData();
		}
	}, [query]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				marginTop: "10px",
				marginBottom: "10px",
				alignItems: "center",
				backgroundColor: "transparent",
				justifyContent: "space-between",
				flexWrap: "wrap",
			}}
		>
			{header ? <p style={textStyle.header}>{header}</p> : null}
			<div style={{ alignItems: "center", display: "flex" }}>
				<TextField
					autoFocus
					variant="outlined"
					label="ID Agrisus"
					sx={{ backgroundColor: "white" }}
					value={query}
					onChange={(event) => setQuery(event.target.value)}
				/>
				<Button
					variant="outlined"
					style={{ marginLeft: "10px", height: "fit-content" }}
					onClick={() => {
						setQuery("");
					}}
				>
					LIMPAR
				</Button>
			</div>
		</div>
	);
};
