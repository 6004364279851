import * as React from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import notificar from "../../../components/notificar";

import * as api from "./api";

export function CadastrarCoordenador({ open, setOpen, reloadData }) {
	const handleClose = () => {
		setNome("");
		setEmail("");
		setSenha("");
		setCpf("");
		setUf("");
		setInstituicao("");
		setOpen(false);
	};

	const [nome, setNome] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [senha, setSenha] = React.useState("");
	const [cpf, setCpf] = React.useState("");
	const [uf, setUf] = React.useState("");
	const [instituicao, setInstituicao] = React.useState("");

	const registraNovoUsuario = async () => {
		try {
			if (!nome || !email || !senha || !cpf || !uf || !instituicao)
				return notificar("Preencha todos os campos!", "error");
			else {
				let request = await api.registraCoordenador(
					JSON.stringify({
						nome,
						email,
						senha,
						cpf,
						uf,
						instituicao,
					})
				);
				if (request.status === 200) {
					let json = await request.json();
					notificar(json, "success");
					reloadData();
					handleClose();
				} else {
					let json = await request.json();
					notificar(json, "error");
				}
			}
		} catch (err) {
			console.error(err);
			notificar("Erro ao registrar novo usuário", "error");
		}
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} key="hasgduasgdjasgduys">
				<DialogTitle>Cadastro de Coordenador</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Cadastre aqui um novo coordenador, que poderá submeter e acompanhar propostas no sistema.
					</DialogContentText>
					<TextField
						value={nome}
						onChange={(event) => setNome(event.target.value)}
						autoFocus
						margin="dense"
						id="name"
						label="Nome"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={instituicao}
						onChange={(event) => setInstituicao(event.target.value)}
						autoFocus
						margin="dense"
						id="instituicao"
						label="Instituição"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={cpf}
						onChange={(event) => setCpf(event.target.value)}
						autoFocus
						margin="dense"
						id="cpf"
						label="CPF"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={uf}
						onChange={(event) => setUf(event.target.value)}
						autoFocus
						margin="dense"
						id="uf"
						label="UF"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
						helperText="Ex: SP, RJ, MG, etc."
					/>
					<TextField
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						autoFocus
						margin="dense"
						id="email"
						label="Email"
						type="email"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={senha}
						onChange={(event) => setSenha(event.target.value)}
						autoFocus
						margin="dense"
						id="password"
						label="Nova Senha"
						type="password"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Voltar</Button>
					<Button onClick={registraNovoUsuario}>Registrar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
