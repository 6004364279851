import Anexo from "../anexo";
import notificar from "../notificar";
import { useState } from "react";

export const AnexoConfiguracoes = ({ value, setValue, text, dbColumn, excel, pdf }) => {
	const [loading, setLoading] = useState(false);

	const enviar = (cleanUpCallback) => {
		if (value) enviarDocumento(value, dbColumn, setValue, cleanUpCallback, setLoading);
		setLoading(true);
	};

	return (
		<Anexo
			text={text}
			value={value}
			setValue={setValue}
			pdf={pdf}
			excel={excel}
			enviar={enviar}
			template={dbColumn}
			loading={loading}
		/>
	);
};

const enviarDocumento = async (value, dbColumn, setValue, cleanUpCallback, setLoading) => {
	let formData = new FormData();
	formData.append(dbColumn, value);
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	let response = await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/configuracoes/enviar-documento`,
		{
			method: "PUT",
			body: formData,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);

	if (response.status === 200) {
		setValue(null);
		setLoading(false);
		cleanUpCallback();

		notificar("Documento enviado com sucesso!", "success");
	} else {
		setLoading(false);
		notificar("Erro ao enviar documento!", "error");
	}
};
