import { useState } from "react";
import { Button, Card, CardActions, CardContent, LinearProgress } from "@mui/material";
import notificar from "../notificar";
import { textStyle } from "../../styles/text";

export default function Anexo({ text, value, setValue, enviar, file, sx, loading, pdf, excel }) {
	const [isAttached, setAttached] = useState(false);
	let inputRef;

	const handleSelection = (event) => {
		setValue(event.target.files[0]);
		setAttached(true);
	};

	const callback = () => {
		if (inputRef) inputRef.value = "";
		setAttached(false);
	};

	return (
		<Card style={{ flexGrow: 1, ...sx }}>
			<input
				accept={
					excel && pdf
						? "application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						: excel && !pdf
						? "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						: "application/pdf"
				}
				type="file"
				name="file1"
				hidden={true}
				ref={(refParam) => (inputRef = refParam)}
				onChange={handleSelection}
			/>
			<CardContent>
				<p style={textStyle.headerComponent}>{text}</p>
				<p style={textStyle.p}>{value ? value.name : "Nenhum arquivo anexado"}</p>
			</CardContent>
			{loading ? <LinearProgress /> : null}
			<CardActions>
				{isAttached ? (
					<Button
						onClick={() => {
							setAttached(false);
							setValue("");

							inputRef.value = "";
						}}
						style={{ color: "red" }}
					>
						REMOVER
					</Button>
				) : (
					<Button onClick={() => inputRef.click()}>{file ? "Substituir" : "Anexar"}</Button>
				)}
				{isAttached && enviar ? (
					<Button
						onClick={() => {
							if (value) enviar(callback);
						}}
					>
						Enviar
					</Button>
				) : null}
				{file ? (
					<Button onClick={() => cacheFileAndOpen(file)}>
						{file.split(".").pop() === "pdf" ? "Visualizar" : "Baixar"}
					</Button>
				) : (
					<Button disabled style={{ color: "orange" }}>
						Pendente
					</Button>
				)}
			</CardActions>
		</Card>
	);
}

const cacheFileAndOpen = async (file) => {
	let token = await JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;
	let extension = file.split(".").pop();

	if (extension === "pdf") {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				window.open(_url, "_blank").focus();
			})
			.catch((err) => console.error(err));
	} else {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				let link = document.createElement("a");
				link.id = "a";
				link.href = _url;
				link.download = file;
				link.style = "display: none";
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						//view: window,
					})
				);
			})
			.catch((err) => console.error(err));
	}
};
