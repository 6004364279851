export async function submeterProposta(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/submeter-proposta`,
		{
			method: "POST",
			body,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

export async function getPropostas(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/lista-proposta`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
}

export const downloadFile = async (file) => {
	let token = await JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;

	fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			const _url = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.id = "a";
			link.href = _url;
			link.download = file;
			link.style = "display: none";
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					//view: window,
				})
			);
		})
		.catch((err) => console.error(err));
};
