import React from "react";
import { Box, Typography } from "@mui/material";
import { AdicionarRelatorio } from "./adicionarRelatorio";
import Relatorio from "../../../components/relatorios_coordenacao";
import { textStyle } from "../../../styles/text";

export const Relatorios = ({ data, isLoading, setIsLoading, reloadData }) => {
	if (data.em_andamento)
		return (
			<Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<p style={textStyle.header}>Relatórios</p>
					<AdicionarRelatorio data={data} reloadData={reloadData} />
				</Box>
				{data.relatorios.map((relatorio) => (
					<Relatorio
						relatorio={relatorio}
						reloadData={reloadData}
						key={relatorio.id}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
					/>
				))}
			</Box>
		);
	else
		return (
			<Typography variant="subtitle1" align="center" sx={{ fontWeight: "bold", marginTop: "20px" }}>
				Nenhum Relatório Disponível
			</Typography>
		);
};
