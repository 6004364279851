export async function listaTodos() {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/listar`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => response.json());
}

export async function registraCoordenador(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/registro-administrativo`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body,
		}
	);
}

export async function detalhes(idUsuario) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/detalhes?id=${idUsuario}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);
}

export async function atualizarCoordenador(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/atualizar`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body),
	});
}
