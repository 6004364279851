import React, { useEffect, useState } from "react";
import {
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	ButtonGroup,
	CircularProgress,
	Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import notificar from "../../../components/notificar";
import { emitirParecer, detalhesProposta } from "./api";
import { textStyle } from "../../../styles/text";

export default function Categoria1({ reloadData }) {
	const [q1, setQ1] = React.useState(0);
	const [q2, setQ2] = React.useState(0);
	const [q3, setQ3] = React.useState(0);
	const [q4, setQ4] = React.useState(0);

	const [sugerirOrcamento, setSugerirOrcamento] = React.useState(false);
	const [valorSugerido, setValorSugerido] = React.useState(0);
	const [comentario, setComentario] = React.useState("");
	const [deliberacao, setDeliberacao] = React.useState("");
	const [isLoading, setLoading] = useState(true);

	const params = useParams();

	useEffect(() => {
		detalhesProposta(params.idProposta)
			.then((json) => {
				if (json.parecer_dados) prepareData(json);
				else setLoading(false);
			})
			.catch((err) => console.error(err));
	}, []);

	const prepareData = (json) => {
		let data = JSON.parse(json.parecer_dados);

		for (let key in data) {
			if (key === "q1") setQ1(parseInt(data[key]));
			if (key === "q2") setQ2(parseInt(data[key]));
			if (key === "q3") setQ3(parseInt(data[key]));
			if (key === "q4") setQ4(parseInt(data[key]));
			if (key === "deliberacao") setDeliberacao(data[key]);
			if (key === "sugerirOrcamento") setSugerirOrcamento(data[key]);
			if (key === "valorSugerido") setValorSugerido(data[key]);
			if (key === "comentario") setComentario(data[key]);
		}
		setLoading(false);
	};

	const prepareJson = () => {
		let json = { q1, q2, q3, q4 };

		let media1 = (q1 + q2 + q3 + q4) / 4;
		let media = media1.toFixed(1);

		emitirParecer({
			...json,
			media,
			sugerirOrcamento,
			valorSugerido,
			comentario,
			deliberacao,
			id: params.idProposta,
		}).then((json) => posParecer(json));
	};

	const posParecer = (json) => {
		notificar("PARECER ENVIADO!");
		reloadData();
	};

	const media = () => {
		let media1 = (q1 + q2 + q3 + q4) / 4;
		return media1.toFixed(1);
	};

	if (isLoading)
		return (
			<Box sx={{ display: "flex", height: "100%" }}>
				<CircularProgress sx={{ margin: "auto" }} />
			</Box>
		);
	else
		return (
			<div>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<p style={textStyle.header}>Parecer Técnico</p>
					<p style={textStyle.p}>
						Nota Geral:
						{media()}
					</p>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						backgroundColor: "transparent",
					}}
				>
					<h3>Mérito Científico e Tecnológico (Peso 2)</h3>
					<Questao questao={q1} setQuestao={setQ1} texto="1) Público-alvo. " />
					<Questao questao={q2} setQuestao={setQ2} texto="2) Temática do evento e palestrantes." />
					<Questao
						questao={q3}
						setQuestao={setQ3}
						texto="3) Importância do evento e aplicação prática no setor produtivo dos temas a serem discutidos no evento. "
					/>
					<Questao
						questao={q4}
						setQuestao={setQ4}
						texto="4) Orçamento: valor pleiteado e natureza das despesas estão corentes ou propõe alteração? "
					/>

					<TextField
						style={{
							marginTop: "20px",
							backgroundColor: "white",
						}}
						variant="outlined"
						label="Deliberação"
						value={deliberacao}
						onChange={(event) => setDeliberacao(event.target.value)}
					/>

					<div
						style={{
							width: "100%",
							border: "1px solid rgb(170,170,170)",
							borderRadius: "5px",
							padding: "20px",
							marginTop: "20px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "center",
							}}
						>
							<SugerirOrcamento
								sugerirOrcamento={sugerirOrcamento}
								setSugerirOrcamento={setSugerirOrcamento}
							/>
						</div>

						{sugerirOrcamento ? (
							<p style={{ marginTop: "20px", fontWeight: "bold" }}>Valor sugerido</p>
						) : (
							<></>
						)}
						{sugerirOrcamento ? (
							<div>
								<div style={{ display: "flex" }}>
									<p>R$</p>
									<TextField
										type={"number"}
										sx={{
											backgroundColor: "white",
											width: "100%",
											marginLeft: "10px",
										}}
										variant="outlined"
										value={valorSugerido}
										onChange={(event) => setValorSugerido(event.target.value)}
									/>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginTop: "20px",
									}}
								>
									<p>Comentário </p>
									<TextField
										type={"text"}
										sx={{
											backgroundColor: "white",
											width: "100%",
											marginLeft: "10px",
										}}
										variant="outlined"
										value={comentario}
										onChange={(event) => setComentario(event.target.value)}
									/>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					<Button
						variant="outlined"
						sx={{
							width: "100px",
							marginLeft: "auto",
							marginTop: "20px",
						}}
						onClick={prepareJson}
					>
						Emitir
					</Button>
				</div>
			</div>
		);
}

function Questao({ questao, setQuestao, texto }) {
	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<p>
				<strong>{texto}</strong>
			</p>
			<div
				style={{
					justifyContent: "left",
					display: "flex",
				}}
			>
				<FormControl>
					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={questao}
						onChange={(event) => setQuestao(parseInt(event.target.value))}
					>
						<FormControlLabel value={0} control={<Radio />} label="Não Aplicável" />
						<FormControlLabel value={2} control={<Radio />} label="Muito Baixa" />
						<FormControlLabel value={4} control={<Radio />} label="Baixa" />
						<FormControlLabel value={6} control={<Radio />} label="Media" />
						<FormControlLabel value={8} control={<Radio />} label="Alta" />
						<FormControlLabel value={10} control={<Radio />} label="Muito Alta" />
					</RadioGroup>
				</FormControl>
			</div>
		</div>
	);
}

function SugerirOrcamento({ sugerirOrcamento, setSugerirOrcamento }) {
	return (
		<div
			style={{
				flexDirection: "row",
				display: "flex",
				width: "100%",
				marginTop: "auto",
			}}
		>
			<p style={{ marginLeft: "auto" }}>Sugerir um orçamento diferente?</p>
			<ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ marginLeft: "20px" }}>
				<Button onClick={() => setSugerirOrcamento(true)}>Sim</Button>
				<Button onClick={() => setSugerirOrcamento(false)}>Não</Button>
			</ButtonGroup>
		</div>
	);
}
