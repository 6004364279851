import { Button, FormControl, MenuItem, Box, Select, InputLabel } from "@mui/material";
import { arquivarProposta, definirAprovacao, reativarProposta } from "./api";
import { useParams, useNavigate } from "react-router-dom";
import notificar from "../../../components/notificar";
import { useState } from "react";
import { IniciarProposta } from "./modal_iniciar_proposta";

const DefinirRecomendacao = ({ reloadData, data }) => {
	const { idProposta } = useParams();
	const navigate = useNavigate();

	const handleArquivar = async () => {
		arquivarProposta({
			id: idProposta,
		})
			.then(() => notificar("PROPOSTA ARQUIVADA"))
			.then(() => navigate("/secretaria"));
	};

	const handleReativar = async () => {
		reativarProposta({
			id: idProposta,
		})
			.then(() => notificar("PROPOSTA REATIVADA"))
			.then(() => navigate("/secretaria"));
	};

	const propostaFinalizada = () => {
		if (data.relatorios.length === 0 || !data.relatorios) return false;

		for (let relatorio in data.relatorios) {
			if (data.relatorios[relatorio]["status"] !== "APROVADO") {
				return false;
			}
		}

		return true;
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "row", width: "fit-content" }}>
			{!data.em_andamento ? <Recomendacao reloadData={reloadData} data={data} /> : null}
			{(data.parecer === "REPROVADO" || propostaFinalizada()) && data.ativo ? (
				<Button
					onClick={() => handleArquivar()}
					variant="outlined"
					sx={{ height: "fit-content", alignSelf: "center" }}
				>
					ARQUIVAR
				</Button>
			) : null}
			{(propostaFinalizada() && !data.ativo) || (data.parecer === "REPROVADO" && !data.ativo) ? (
				<Button
					onClick={() => handleReativar()}
					variant="outlined"
					sx={{ height: "fit-content", alignSelf: "center" }}
				>
					REATIVAR
				</Button>
			) : null}
			<IniciarProposta data={data} reloadData={reloadData} />
		</Box>
	);
};

const Recomendacao = ({ reloadData, data }) => {
	const [recomendacao, setRecomendacao] = useState(data.parecer_dados ? data.parecer : "EMITIDO");
	const { idProposta } = useParams();

	const handleAprovacao = async (aprovacao) => {
		let notificacao;

		if (aprovacao === "APROVADO") {
			notificacao = "Proposta Recomendada";
		} else {
			notificacao = "Proposta não Recomendada";
		}

		definirAprovacao({
			id: idProposta,
			aprovacao,
		})
			.then(() => reloadData())
			.then(() => notificar(notificacao));
	};

	return (
		<Box sx={{ marginRight: "20px" }}>
			<FormControl sx={{ minWidth: "200px" }} disabled={data.parecer_dados ? false : true}>
				<InputLabel>Recomendação</InputLabel>
				<Select
					label="Recomendação"
					value={recomendacao}
					onChange={(event) => {
						if (event.target.value !== "EMITIDO") {
							setRecomendacao(event.target.value);
							handleAprovacao(event.target.value);
						}
					}}
				>
					<MenuItem value={"EMITIDO"} disabled>
						Não Definida
					</MenuItem>
					<MenuItem value={"APROVADO"}>Recomendada</MenuItem>
					<MenuItem value={"REPROVADO"}>Não Recomendada</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
};

export default DefinirRecomendacao;
