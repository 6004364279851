export async function registrar(body) {
	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/registrar`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	});
}

export async function confirmarEmail(token) {
	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/confirmar-email?token=${token}`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}
	);
}

export async function redefinirSenha(senha, token) {
	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/redefinir-senha?token=${token}`,
		{
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(senha),
		}
	);
}

export async function esqueciMinhaSenha(email) {
	return await fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/resetar-senha`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(email),
	});
}
