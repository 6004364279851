import { Button, Select, MenuItem, FormControl, InputLabel, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import * as api from "./api";
import { useParams } from "react-router-dom";
import notificar from "../../../components/notificar";

const AtribuirAssessor = ({ open, setOpen, textoBotao, idAssessor, reloadData }) => {
	const [assessores, setAssessores] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [selected, setSelected] = useState(idAssessor ? idAssessor : 0);
	const { idProposta } = useParams();

	const loadData = () => {
		api.listarAcessores().then((json) => prepareData(json));
	};

	const prepareData = (data) => {
		let dataCopy = data.map((acessor) => {
			return { nome: acessor.nome, id: acessor.id };
		});

		if (!idAssessor) dataCopy.unshift({ nome: "Nenhum", id: 0 });

		setAssessores(dataCopy);
		setLoading(false);
	};

	const designarAcessor = async (event) => {
		let data = {
			idAcessor: event.target.value,
			idProposta: parseInt(idProposta),
		};

		let request = await api.designarAcessor(data).catch((err) => console.error(err));
		if (request.status === 200) {
			setLoading(true);
			setSelected(event.target.value);
			loadData();
			notificar("Assessor designado com sucesso!", "success");
		} else {
			notificar("Ocorreu um erro ao designar o assessor!", "error");
		}
	};

	useEffect(() => loadData(), []);

	if (!isLoading)
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<FormControl sx={{ minWidth: "200px" }}>
					<InputLabel id="demo-simple-select-label">Assessor Reponsável</InputLabel>
					<Select displayEmpty label="Assessor Reponsável" value={selected} onChange={designarAcessor}>
						{assessores.map((acessor) => (
							<MenuItem value={acessor.id} key={Math.random()}>
								{acessor.nome}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		);
	else
		return (
			<div style={{ minWidth: "200px", justifyContent: "center", display: "flex" }}>
				<CircularProgress />
			</div>
		);
};

export default AtribuirAssessor;
