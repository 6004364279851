import * as React from "react";
import { Box, DialogTitle, DialogContent, DialogActions, Dialog, Button, TextField } from "@mui/material";
import axios from "axios";
import notificar from "../notificar";

export default function AvaliarRelatorio({ open, setOpen, reloadData, setIsLoading, relatorio, id_arquivo }) {
	let inputRef;
	let excel = false;
	let pdf = true;
	const [file, setFile] = React.useState("");
	const [attached, setAttached] = React.useState(false);
	const [comentario, setComentario] = React.useState("");

	const handleSelection = (event) => {
		setFile(event.target.files[0]);
		setAttached(true);
	};

	const removerAnexo = () => {
		if (inputRef) inputRef.value = "";
		setAttached(false);
		setFile("");
	};

	const handleClose = () => {
		if (inputRef) inputRef.value = "";
		setFile("");
		setAttached(false);
		setOpen(false);
	};

	const handleUpload = async (status) => {
		let formData = new FormData();
		formData.append("anexo", file);
		formData.append("id_relatorio", relatorio.id);
		formData.append("id_arquivo", id_arquivo);
		formData.append("texto", comentario);
		formData.append("status", status);
		setIsLoading(true);
		const user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.state.token;

		const config = {
			onUploadProgress: (progressEvent) => {
				console.log(Math.floor(progressEvent.progress * 100));
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		try {
			let response = await axios.put(
				`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/relatorio/enviar-deliberacao`,
				formData,
				config
			);

			notificar(response.data, "success");
			reloadData();
		} catch {
			setIsLoading(false);
			notificar("Erro ao enviar o arquivo", "error");
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Avaliar Relatório</DialogTitle>
			<DialogContent sx={{ width: "600px" }}>
				<Box sx={{ display: "flex", flexDirection: "row" }}>
					<input
						accept={
							excel && pdf
								? "application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								: excel && !pdf
								? "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								: "application/pdf"
						}
						type="file"
						name="file1"
						hidden={true}
						ref={(refParam) => (inputRef = refParam)}
						onChange={handleSelection}
					/>
					<p>{file ? file.name : "Nenhum arquivo anexado"}</p>
					{attached ? (
						<React.Fragment>
							<Button sx={{ marginLeft: "auto" }} onClick={removerAnexo}>
								Remover
							</Button>
						</React.Fragment>
					) : (
						<Button sx={{ marginLeft: "auto" }} onClick={() => inputRef.click()}>
							Anexar
						</Button>
					)}
				</Box>
				<TextField
					multiline
					fullWidth
					label="Comentário"
					value={comentario}
					onChange={(event) => setComentario(event.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} sx={{ marginRight: "auto" }}>
					Voltar
				</Button>
				<Button onClick={() => handleUpload("REPROVADO")} sx={{ color: "red" }}>
					Reprovar
				</Button>
				<Button onClick={() => handleUpload("APROVADO")}>Aprovar</Button>
			</DialogActions>
		</Dialog>
	);
}
