import styles from "./registro.module.css";
import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import background from "../../assets/fundo-registro-fhd.jpg";
import logo from "../../assets/logo.png";
import * as api from "./api";
import ModalEmailEmUso from "./modal_email_em_uso";
import notificar from "../../components/notificar/index";

const Registro = () => {
	return (
		<div
			id={styles.container}
			style={{
				backgroundImage: `url(${background})`,
				backgroundSize: "cover",
			}}
		>
			<div id={styles.content}>
				<div id={styles.header}>
					<h1 id={styles.headerText}>CRIE UMA CONTA</h1>
					<Link to="/" id={styles.headerText2}>
						OU FAÇA LOGIN
					</Link>
				</div>
				<div id={styles.headerSeparator}></div>
				<Outlet />
			</div>
		</div>
	);
};

const Registrar = () => {
	const [open, setOpen] = useState(false);
	const [instituicao, setInst] = useState("");
	const [email, setEmail] = useState("");
	const [senha, setSenha] = useState("");
	const [nome, setNome] = useState("");
	const [cpf, setCPF] = useState("");
	const [error, setError] = useState(false);
	const [uf, setUF] = useState("");
	const [errorMessage, setMessage] = useState("This is an error!");
	const navigate = useNavigate();

	return (
		<div id={styles.form}>
			<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />
			<ModalEmailEmUso
				open={open}
				setOpen={setOpen}
				email={email}
				handleEsqueciMinhaSenha={handleEsqueciMinhaSenha}
			/>
			<TextField
				label="Nome"
				error={error}
				value={nome}
				variant="outlined"
				onChange={(event) => setNome(event.target.value)}
			/>
			<TextField
				style={{ marginTop: "10px" }}
				label="CPF"
				error={error}
				value={cpf}
				variant="outlined"
				onChange={(event) => setCPF(event.target.value)}
			/>
			<TextField
				style={{ marginTop: "10px" }}
				error={error}
				label="Instituição"
				variant="outlined"
				value={instituicao}
				onChange={(event) => setInst(event.target.value)}
			/>

			<TextField
				style={{ marginTop: "10px" }}
				error={error}
				label="UF"
				placeholder="Ex: SP, MG, RN"
				variant="outlined"
				value={uf}
				onChange={(event) => setUF(event.target.value)}
			/>

			<TextField
				style={{ marginTop: "10px" }}
				error={error}
				label="Email"
				variant="outlined"
				value={email}
				onChange={(event) => setEmail(event.target.value)}
			/>
			<TextField
				style={{ marginTop: "10px" }}
				error={error}
				label="Senha"
				type="password"
				variant="outlined"
				value={senha}
				onChange={(event) => setSenha(event.target.value)}
			/>
			<Button
				id={styles.button}
				onClick={() =>
					handleRegistrar(instituicao, email, senha, nome, cpf, uf, setError, setMessage, navigate, setOpen)
				}
			>
				CADASTRAR
			</Button>

			{error ? <p id={styles.errorMessage}>{errorMessage}</p> : <></>}
		</div>
	);
};

const Sucesso = () => (
	<div id={styles.form}>
		<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />
		<p id={styles.successText1}>Só falta mais uma etapa!</p>
		<p id={styles.successText2}>
			Em instantes você receberá um email de confirmação, basta clicar no link que lhe enviaremos para ativar sua
			conta.
		</p>
	</div>
);

const Confirmacao = () => {
	const { token } = useParams();
	api.confirmarEmail(token).catch((err) => console.error(err));

	return (
		<div id={styles.form}>
			<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />
			<p id={styles.successText1}>Obrigado por confirmar seu endereço de email</p>
			<p id={styles.successText2}>
				Basta voltar á tela de <Link to="/">login</Link>, e fazer o acesso com os dados que acabou de cadastrar
			</p>
		</div>
	);
};

const RedefinirSenha = () => {
	const [senha, setSenha] = useState("");
	const [sucesso, setSucesso] = useState(false);

	const navigate = useNavigate();
	const { token } = useParams();

	return (
		<div id={styles.form}>
			<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />

			{sucesso ? (
				<>
					<p id={styles.textPwdReset}>
						<strong>Tudo pronto!</strong>
						<br />
						<br />
						Agora é só fazer login com seu email e a senha que acabou de criar.
					</p>
					<Button id={styles.button} onClick={() => navigate("/")}>
						FAZER LOGIN
					</Button>
				</>
			) : (
				<>
					<p id={styles.textPwdReset}>
						<strong>Escolha sua nova senha</strong>
						<br />
						Uma senha forte deve conter letras maiúsculas e minúsculas, números, e símbolos
					</p>
					<TextField
						style={{ marginTop: "10px" }}
						label="Senha"
						type="password"
						variant="outlined"
						value={senha}
						onChange={(event) => setSenha(event.target.value)}
					/>
					<Button id={styles.button} onClick={() => handleRedefinirSenha(senha, token, setSucesso)}>
						ALTERAR SENHA
					</Button>
				</>
			)}
		</div>
	);
};

const EsqueciMinhaSenha = () => {
	const [email, setEmail] = useState("");
	const [sucesso, setSucesso] = useState(false);

	return (
		<div id={styles.form}>
			<img id={styles.logo} src={logo} alt="Logo Fundação Agrisus" />

			{sucesso ? (
				<>
					<p id={styles.textPwdReset}>
						<strong>Quase lá!</strong>
						<br />
						<br />
						Se a conta informada existir, enviaremos um email com instruções para prosseguir com a
						redefinição da sua senha.
					</p>
				</>
			) : (
				<>
					<p id={styles.textPwdReset}>
						<strong>Esqueceu sua senha?</strong>
						<br />
						Informe o email de cadastro
					</p>
					<TextField
						style={{ marginTop: "10px" }}
						label="Email"
						type="email"
						variant="outlined"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
					/>
					<Button id={styles.button} onClick={() => handleEsqueciMinhaSenha(email, setSucesso)}>
						REDEFINIR SENHA
					</Button>
				</>
			)}
		</div>
	);
};

export { Registro, Registrar, Sucesso, Confirmacao, RedefinirSenha, EsqueciMinhaSenha };

const handleEsqueciMinhaSenha = async (email, setSucesso) => {
	let response = await api.esqueciMinhaSenha({ email: email });

	if (response.status === 200) setSucesso(true);
};

const handleRedefinirSenha = async (senha, token, setSucesso) => {
	let response = await api.redefinirSenha({ senha }, token);
	if (response.status === 200) setSucesso(true);
};

const handleRegistrar = async (instituicao, email, senha, nome, cpf, uf, setError, setMessage, navigate, setOpen) => {
	let response = await api.registrar({
		instituicao,
		email,
		senha,
		nome,
		cpf,
		uf,
	});
	if (response.status === 200) {
		notificar("Sucesso!", "success");
		navigate("sucesso");
	}

	if (response.status === 500) {
		notificar("Erro interno", "error");
	}

	if (response.status === 201) {
		notificar("Email em uso", "warning");
		setOpen(true);
	}

	if (response.status === 400) {
		response = await response.json();
		console.log(response);

		notificar(response.message, "warning");
	}
};
