import * as React from "react";
import {
	TextField,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	Box,
} from "@mui/material";
import notificar from "../notificar";

export default function ModificarRelatorio({ data, reloadData }) {
	const [open, setOpen] = React.useState(false);
	const [confirmDeletion, setConfirmDeletion] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTittle(data.titulo);
		setConfirmDeletion(false);
	};

	const [date, setDate] = React.useState(null);
	const [tittle, setTittle] = React.useState(data.titulo);

	React.useEffect(() => {
		let dd = new Date(data.prazo).getUTCDate().toLocaleString("pt-BR", {
			minimumIntegerDigits: 2,
			useGrouping: false,
			timeZone: "UTC",
		});
		let mm = new Date(data.prazo).getUTCMonth() + 1;
		mm = mm.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
		let yyyy = new Date(data.prazo).getFullYear();
		dd = yyyy + "-" + mm + "-" + dd;

		setDate(dd);
	}, []);

	const handleUpdate = async () => {
		let query = await fetch(
			`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/relatorio/modificar`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					id: data.id,
					titulo: tittle,
					prazo: date,
				}),
			}
		).catch((err) => console.log(err));

		if (query.status === 200) {
			notificar("Relatório modificado com sucesso", "success");
			setOpen(false);
			reloadData();
		} else {
			notificar("Erro ao modificar relatório", "error");
		}
	};

	const handleDelete = async () => {
		let query = await fetch(
			`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/relatorio/deletar`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					id: data.id,
				}),
			}
		).catch((err) => console.log(err));

		if (query.status === 200) {
			notificar("Relatório deletado com sucesso", "success");
			setOpen(false);
			reloadData();
		} else {
			notificar("Erro ao deletar relatório", "error");
		}
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Modificar
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{confirmDeletion ? (
					<>
						<DialogTitle id="alert-dialog-title">Deletar Relatório</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									rowGap: "10px",
									paddingTop: "10px",
									alignItems: "center",
								}}
							>
								<DialogContentText id="alert-dialog-description">
									Tem certeza que deseja deletar o relatório "{data.titulo}"?
								</DialogContentText>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setConfirmDeletion(false)}>Voltar</Button>
							<Button sx={{ color: "red" }} onClick={handleDelete}>
								DELETAR
							</Button>
						</DialogActions>
					</>
				) : (
					<>
						<DialogTitle id="alert-dialog-title">Modificar Relatório</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									rowGap: "10px",
									paddingTop: "10px",
									alignItems: "center",
								}}
							>
								<TextField
									label="Título do Relatório"
									value={tittle}
									onChange={(event) => setTittle(event.target.value)}
								/>
								<input type="date" value={date} onChange={(event) => setDate(event.target.value)} />
								<DialogContentText id="alert-dialog-description">
									Modifique o título do relatório e o prazo final para a entrega.
								</DialogContentText>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button sx={{ marginRight: "auto", color: "red" }} onClick={() => setConfirmDeletion(true)}>
								DELETAR
							</Button>
							<Button onClick={handleClose}>Sair</Button>
							<Button onClick={handleUpdate} autoFocus>
								Salvar
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		</div>
	);
}
