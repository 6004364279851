import notificar from "./notificar";

export const downloadFile = async (file) => {
	let token = await JSON.parse(sessionStorage.getItem("user"));
	token = token.state.token;
	let extension = file.split(".").pop();

	if (extension === "pdf") {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				window.open(_url, "_blank").focus();
			})
			.catch((err) => console.error(err));
	} else {
		fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/usuario/download?fl=${file}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 200) return response.blob();
				else {
					notificar("Erro ao baixar arquivo", "error");
					throw new Error("Erro ao baixar arquivo");
				}
			})
			.then((blob) => {
				const _url = window.URL.createObjectURL(blob);
				let link = document.createElement("a");
				link.id = "a";
				link.href = _url;
				link.download = file;
				link.style = "display: none";
				link.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						//view: window,
					})
				);
			})
			.catch((err) => console.error(err));
	}
};
