import styles from "./acompanhamento.module.css";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import React from "react";
import * as api from "./api";
import Parecer from "./parecer";
import Anexos from "./anexos";
import Detalhes from "./detalhes";
import DocumentosPendentes from "./documentosPendentes";
import useUserStore from "../../../stores/user";
import Relatorio from "../../../components/relatorios_coordenador";
import { Solicitacoes } from "./solicitacoes";
import { useNavigate } from "react-router-dom";
import plant from "../../../assets/plant.png";
import { textStyle } from "../../../styles/text";

export default function Acompanhamento() {
	const [data, setData] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(true);
	const userStore = useUserStore((state) => state);
	const navigate = useNavigate();

	React.useEffect(() => {
		loadData();
	}, []);

	const [tab, setTab] = React.useState(0);

	const loadData = async () => {
		try {
			const response = await api.getProjects({ id: userStore.id }, userStore.token);
			if (response.status === 200) {
				let data = await response.json();
				setData(data);
				setIsLoading(false);
			} else if (response.status === 401) {
				navigate("/");
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	if (!isLoading)
		return (
			<React.Fragment>
				{!data ? (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							backgroundColor: "transparent",
							height: "100%",
						}}
					>
						<img src={plant} height="100px" alt="planta verde" />
						<p style={textStyle.header2}>Nenhuma proposta ativa</p>
					</div>
				) : (
					<div id={styles.container}>
						<Tabs
							variant="scrollable"
							value={tab}
							onChange={(event, newValue) => setTab(newValue)}
							sx={{ height: "fit-content", color: "white", flexGrow: 1 }}
						>
							<Tab label="Documentação" />
							<Tab label="Relatórios" disabled={data.em_andamento ? false : true} />

							<Tab label="Solicitar Alterações" />

							{"aba oculta mediante pedido"}
							{false ? (
								<Tab
									label="Parecer Técnico"
									disabled={
										data.parecer === "APROVADO" || data.parecer === "REPROVADO" ? false : true
									}
								/>
							) : null}
						</Tabs>

						{data.relatorios && tab === 1 ? (
							<p style={{ ...textStyle.header, marginBottom: "10px", marginTop: "10px" }}>Relatórios</p>
						) : null}
						{data.relatorios && tab === 1
							? data.relatorios.map((item) => (
									<Relatorio
										data={item}
										key={Math.random()}
										reloadData={loadData}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
									/>
							  ))
							: null}

						{tab === 2 ? (
							<Solicitacoes data={data} reloadData={loadData} setLoading={setIsLoading} />
						) : null}
						{tab === 0 ? <Anexos data={data} /> : null}

						{data.parecer === "APROVADO" && tab === 0 ? (
							<DocumentosPendentes data={data} reloadData={loadData} />
						) : null}

						{tab === 3 ? <Parecer data={data} /> : null}

						{tab === 0 ? <Detalhes data={data} setIsLoading={setIsLoading} reloadData={loadData} /> : null}
					</div>
				)}
			</React.Fragment>
		);
	else
		return (
			<div style={{ display: "flex", height: "100%" }}>
				<CircularProgress style={{ margin: "auto" }} />
			</div>
		);
}
