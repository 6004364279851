import * as React from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as api from "./api";
import notificar from "../../../components/notificar";

export default function NovoUsuario({ isOpen, setOpen, reloadData }) {
	const handleClose = () => {
		setOpen(false);
	};

	const [nome, setNome] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [senha, setSenha] = React.useState("");

	const registraNovoUsuario = async () => {
		if (nome === "" || email === "" || senha === "") {
			notificar("Preencha todos os campos", "error");
			return;
		}
		try {
			let request = await api.registraAssessor(
				JSON.stringify({
					nome,
					email,
					senha,
				})
			);

			if (request.status === 200) {
				request.json().then((json) => notificar(json, "success"));

				setNome("");
				setEmail("");
				setSenha("");
				setOpen(false);
				reloadData();
			} else {
				request.json().then((json) => notificar(json, "error"));
			}
		} catch {
			notificar("Erro ao registrar novo usuário", "error");
		}
	};

	return (
		<div>
			<Dialog open={isOpen} onClose={handleClose}>
				<DialogTitle>Cadastro de Assessor</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Cadastre aqui um novo assessor técnico, com acesso à avaliação de propostas
					</DialogContentText>
					<TextField
						value={nome}
						onChange={(event) => setNome(event.target.value)}
						autoFocus
						margin="dense"
						id="nome"
						label="Nome"
						type="text"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						autoFocus
						margin="dense"
						id="email"
						label="Email"
						type="email"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
					<TextField
						value={senha}
						onChange={(event) => setSenha(event.target.value)}
						autoFocus
						margin="dense"
						id="senha"
						label="Senha"
						type="password"
						fullWidth
						variant="standard"
						sx={{ marginTop: "10px" }}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={registraNovoUsuario}>Registrar</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
