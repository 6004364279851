import styles from "./acompanhamento.module.css";
import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from "./api";
import { useParams } from "react-router-dom";
import Status from "./status";
import Anexos from "./anexos";
import Detalhes from "./detalhes";
import Relatorio from "../../../components/relatorios_assessor";
import { Solicitacoes } from "./solicitacoes";
import useUserStore from "../../../stores/user";
import { Loader } from "../../../components/Loader";
import EmitirParecer from "../EmitirParecer";
import { textStyle } from "../../../styles/text";

export default function DetalhesProposta() {
	const [data, setData] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [tab, setTab] = useState(0);
	const { idProposta } = useParams();
	const token = useUserStore((state) => state.token);

	useEffect(() => loadData(), []);

	const loadData = () => {
		setIsLoading(true);
		api.getProjects(idProposta, token).then((json) => {
			if (json !== "ERROR") {
				setData(json);
				setIsLoading(false);
			}
		});
	};

	if (!isLoading)
		return (
			<>
				{data ? (
					<div id={styles.container}>
						<Tabs
							variant="scrollable"
							value={tab}
							onChange={(event, newValue) => setTab(newValue)}
							sx={{ height: "fit-content", color: "white" }}
						>
							<Tab label="Documentação" />
							<Tab label="Relatórios" disabled={data.em_andamento ? false : true} />
							<Tab label="Alterações Solicitadas" />
							<Tab label="Parecer Técnico" />
						</Tabs>

						{tab === 0 ? (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<p style={{ ...textStyle.header }}>Documentação</p>
								<Status dados={data} />
							</div>
						) : null}
						{tab === 0 ? <Anexos data={data} /> : null}
						{tab === 0 ? <Detalhes data={data} /> : null}

						{tab === 1 && data.relatorios.length !== 0 ? (
							<p style={{ ...textStyle.header }}>Relatórios</p>
						) : tab === 1 && data.relatorios.length === 0 ? (
							<p variant="subtitle1" align="center" style={{ ...textStyle.header2, marginTop: "10px" }}>
								Nenhum Relatório Disponível
							</p>
						) : null}
						{tab === 1
							? data.relatorios.map((relatorio) => (
									<Relatorio
										relatorio={relatorio}
										reloadData={loadData}
										key={relatorio.id}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
									/>
							  ))
							: null}

						{tab === 2 ? <Solicitacoes data={data} reloadData={loadData} /> : null}
						{tab === 3 ? <EmitirParecer data={data} reloadData={loadData} /> : null}
					</div>
				) : (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<h2>Nenhum projeto enviado</h2>
					</div>
				)}
			</>
		);
	else return <Loader />;
}
