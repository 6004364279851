export async function emitirParecer(body) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/emitir-parecer`,
		{
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		}
	).then((response) => response.json());
}

export async function detalhesProposta(id_proposta) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/detalhes?id=${id_proposta}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	).then((response) => response.json());
}
