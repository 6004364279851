import { Box, TextField, InputAdornment } from "@mui/material";
import React from "react";

const sx = {
	backgroundColor: "white",
	flexGrow: 1,
};

const handleCategoria = (categoria) => {
	switch (categoria) {
		case 1:
			return "Projeto de Pesquisa sem Bolsa de Iniciação Científica";
		case 2:
			return "Projeto de Pesquisa com Bolsa de Iniciação Científica";
		case 3:
			return "Financiamento de Eventos Técnicos / Demonstrações a campo";
		default:
			return "Outro";
	}
};

const Detalhes = ({ data }) => {
	const variant = "outlined";

	return (
		<Box
			component="form"
			sx={{
				backgroundColor: "transparent",
				justifyContent: "space-evenly",
				display: "flex",
				flexWrap: "wrap",
				rowGap: "20px",
				marginTop: "50px",
				paddingBottom: "50px",
				columnGap: 2,
			}}
		>
			<TextField
				value={data.resumo_da_proposta}
				//onChange={(event) => setResumo(event.target.value)}
				label="Titulo da Proposta"
				multiline
				fullWidth
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.pesquisador.nome}
				label="Coordenador"
				multiline
				fullWidth
				disabled={false}
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.pesquisador.instituicao}
				label="Instituição"
				multiline
				fullWidth
				disabled={false}
				sx={sx}
				variant={variant}
			/>
			<TextField value={data.id} label="ID da Proposta" sx={sx} variant={variant} />
			<TextField
				value={data.protocolo_abertura[0].pa + "/" + data.protocolo_abertura[0].ano}
				//onChange={(event) => setIdAgrisus(event.target.value)}
				label="Protocolo de Abertura"
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.id_fealq}
				//onChange={(event) => setIdFealq(event.target.value)}
				label="ID Fealq"
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.edital}
				//onChange={(event) => setEdital(event.target.value)}
				label="Edital"
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.categoria}
				//onChange={(event) => setCategoria(event.target.value)}
				label="Categoria"
				sx={sx}
				variant={variant}
			/>
			{data.categoria === 3 ? (
				<>
					<TextField value={data.inicio_evento} label="Início do Evento" sx={sx} variant={variant} />
					<TextField value={data.termino_evento} label="Término do Evento" sx={sx} variant={variant} />
				</>
			) : null}
			<TextField
				fullWidth
				value={handleCategoria(data.categoria)}
				label="Descrição da categoria"
				sx={sx}
				variant={variant}
			/>
			<TextField
				value={data.valor_do_projeto}
				label="Valor Solicitado"
				type="text"
				sx={sx}
				variant={variant}
				InputProps={{
					startAdornment: <InputAdornment position="start">R$</InputAdornment>,
				}}
			/>
			{data.valor_financiamento_externo !== "0" ? (
				<div
					style={{
						flexGrow: 1,
						width: "100%",
						display: "flex",
						columnGap: "15px",
						flexWrap: "wrap",
						rowGap: "20px",
					}}
				>
					<TextField
						value={data.razao_social_financiadora}
						label="Razão Social da Entidade Financiadora Externa"
						sx={sx}
						variant={variant}
					/>
					<TextField
						value={data.valor_financiamento_externo}
						label="Valor Financiado"
						sx={sx}
						variant={variant}
						InputProps={{
							startAdornment: <InputAdornment position="start">R$</InputAdornment>,
						}}
					/>
				</div>
			) : null}
			<TextField value={data.tempo_de_vigencia} label="Vigência" sx={sx} variant={variant} />
		</Box>
	);
};

export default Detalhes;
