import { Warning, CheckBox } from "@mui/icons-material";
import { textStyle } from "../../../styles/text";

const Status = ({ dados }) => {
	if (dados.parecer === "EMITIDO" || dados.parecer === "REPROVADO" || dados.parecer === "APROVADO")
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					width: "fit-content",
					height: "fit-content",
					flexWrap: "wrap",
				}}
			>
				{dados.parecer === "REPROVADO" || dados.parecer === "EMITIDO" ? (
					<Warning sx={{ color: "orange", marginRight: "5px" }} />
				) : (
					<CheckBox sx={{ color: "green", marginRight: "5px" }} />
				)}
				<p style={textStyle.p}>
					{dados.parecer === "EMITIDO"
						? "Parecer Emitido"
						: dados.parecer === "APROVADO" && !dados.em_andamento
						? "Proposta Aprovada"
						: dados.parecer === "APROVADO" && dados.em_andamento
						? "Proposta em Andamento"
						: "Proposta não Recomendada"}
				</p>
			</div>
		);
	else
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Warning sx={{ color: "orange", marginRight: "5	px" }} />
				<p style={textStyle.p}>Aguardando análise </p>
			</div>
		);
};

export default Status;
