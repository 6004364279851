import React from "react";
import TabelaAssessores from "./tabelaAssessores";
import { Button } from "@mui/material";
import { ModificarAssessor } from "./modificarAssessor";
import { textStyle } from "../../../styles/text";

export default function Usuarios() {
	const [isOpen, setOpen] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [nome, setNome] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [id, setId] = React.useState("");
	const [displayTable, setDisplayTable] = React.useState(true);

	const handleSelected = (data) => {
		setNome(data.nome);
		setEmail(data.email);
		setId(data.id);
		setOpenEdit(true);
	};

	const reloadData = () => {
		setDisplayTable(false);
		setTimeout(() => {
			setDisplayTable(true);
		}, 100);
	};

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<p style={textStyle.header}>Assessores</p>
				<Button variant="outlined" onClick={() => setOpen(true)}>
					Cadastrar Assessor
				</Button>
			</div>
			{openEdit ? (
				<ModificarAssessor
					open={openEdit}
					setOpen={setOpenEdit}
					nome={nome}
					email={email}
					id={id}
					reloadData={reloadData}
				/>
			) : null}
			{displayTable ? (
				<TabelaAssessores isOpen={isOpen} setOpen={setOpen} handleSelected={handleSelected} />
			) : null}
		</div>
	);
}
