import Anexo from "../../../components/anexo";
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	InputAdornment,
} from "@mui/material";
import React from "react";
import { submeterProposta } from "./api";
import { Warning } from "@mui/icons-material";
import notificar from "../../../components/notificar/index";
import { useNavigate } from "react-router-dom";

export default function Documentacao() {
	const variant = "outlined";
	const [fonteAdicional, setFonte] = React.useState(false);
	const [toggleAdicional, setToggleAdicional] = React.useState(false);

	const [anexoProposta, setAnexoProposta] = React.useState("");
	const [anuencia, setAnuencia] = React.useState("");
	const [planilhaOrcamentaria, setPlanilhaOrcamentaria] = React.useState("");
	const [docAdicional, setDocAdicional] = React.useState("");
	const [vigencia, setVigencia] = React.useState("12 meses");
	const [descricaoDocAdicional, setDescricaoDocAdicional] = React.useState("");
	const [tituloProposta, setTituloProposta] = React.useState("");
	const [valorProposta, setValorProposta] = React.useState(0);
	const [financiamentoAdicional, setFinanciamentoAdicional] = React.useState(0);
	const [razaoSocialFinanciadora, setRazaoSocialFinanciadora] = React.useState("");

	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();

	const handleSubmission = async () => {
		setLoading(true);
		if (
			!anexoProposta ||
			!planilhaOrcamentaria ||
			!anuencia ||
			!tituloProposta ||
			!valorProposta ||
			!valorProposta
		) {
			notificar("Preencha todos os campos obrigatórios", "error");
			setLoading(false);
		} else {
			let formData = new FormData();
			formData.append("proposta", anexoProposta);
			formData.append("resumo_da_proposta", tituloProposta);
			formData.append("carta_de_anuencia", anuencia);
			formData.append("plano_de_recursos", planilhaOrcamentaria);
			formData.append("vigencia", vigencia);
			formData.append("categoria", 1);
			formData.append("valor_do_projeto", valorProposta);

			if (toggleAdicional) {
				formData.append("doc_adicional", docAdicional);
				formData.append("descricao_doc_adicional", descricaoDocAdicional);
			}

			if (fonteAdicional) {
				formData.append("valor_financiamento_externo", financiamentoAdicional);
				formData.append("razao_social_financiadora", razaoSocialFinanciadora);
			}

			let request = await submeterProposta(formData).catch((err) => console.error(err));
			if (request.status === 200) {
				notificar("Proposta enviada com sucesso!", "success");
				navigate("/pesquisador");
				setTimeout(() => {
					window.open(process.env.REACT_APP_PESQUISA_URL);
				}, 3000);
			} else {
				notificar("Erro ao enviar proposta", "error");
				setLoading(false);
			}
		}
	};

	const handleCurrency = (event, setState) => {
		let value = event.target.value;
		value = value.replace(/\D/g, "");
		value = value.replace(/(\d)(\d{2})$/, "$1,$2");
		value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
		setState(value);
	};

	if (loading)
		return (
			<div style={{ margin: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
				<CircularProgress />
				<h3>Enviando...</h3>
			</div>
		);
	else
		return (
			<div>
				<div
					style={{
						width: "100%",
						justifyContent: "left",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						color: "gray",
					}}
				>
					<Warning style={{ marginRight: "5px", color: "orange" }} />
					<p>Somente serão aceitos documentos no formato PDF</p>
				</div>
				<Anexos
					proposta={anexoProposta}
					setProposta={setAnexoProposta}
					anuencia={anuencia}
					setAnuencia={setAnuencia}
					planilhaOrcamentaria={planilhaOrcamentaria}
					setPlanilhaOrcamentaria={setPlanilhaOrcamentaria}
				/>

				{/*ANEXAR DOC ADICIONAL?*/}
				<div
					style={{
						width: "100%",
						border: "1px solid rgb(170,170,170)",
						borderRadius: "5px",
						padding: "20px",
						marginTop: "20px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							justifyContent: "center",
							flexWrap: "wrap-reverse",
							rowGap: 10,
						}}
					>
						{toggleAdicional ? (
							<Anexo pdf text="Documento" value={docAdicional} setValue={setDocAdicional} />
						) : null}
						<ToggleDocAdicional
							toggleAdicional={toggleAdicional}
							setToggleAdicional={setToggleAdicional}
							setDocAdicional={setDocAdicional}
						/>
					</div>

					{toggleAdicional ? (
						<p style={{ marginTop: "20px", fontWeight: "bold" }}>
							Descreva a finalidade do documento anexado
						</p>
					) : null}
					{toggleAdicional ? (
						<TextField
							variant={variant}
							type={"text"}
							sx={{
								backgroundColor: "white",
								width: "100%",
							}}
							onChange={(event) => setDescricaoDocAdicional(event.target.value)}
						/>
					) : null}
				</div>

				{/*INPUT DESCRICAO DA PROPOSTA*/}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<div style={{ marginTop: "10px", width: "100%" }}>
						<p style={{ fontWeight: "bold" }}>Título da proposta</p>
						<TextField
							variant={variant}
							type={"text"}
							sx={{ backgroundColor: "white", width: "100%" }}
							value={tituloProposta}
							onChange={(event) => setTituloProposta(event.target.value)}
						/>
					</div>
				</div>
				{/*DURACAO DA PROPOSTA*/}
				<FormControl fullWidth variant={variant} sx={{ marginTop: "20px" }}>
					<InputLabel>Tempo de Vigência do Projeto</InputLabel>
					<Select
						label="Tempo de Vigência do Projeto"
						value={vigencia}
						onChange={(event) => setVigencia(event.target.value)}
					>
						<MenuItem value="12 meses">12 meses</MenuItem>
						<MenuItem value="18 meses">18 meses</MenuItem>
						<MenuItem value="24 meses">24 meses</MenuItem>
					</Select>
				</FormControl>
				{/*INPUT VALOR DA PROPOSTA*/}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						flexWrap: "wrap",
						columnGap: 10,
					}}
				>
					<div style={{ marginTop: "10px", display: "flex", flexDirection: "column", flexGrow: 1 }}>
						<p style={{ fontWeight: "bold" }}>Valor da Proposta</p>
						<div style={{ display: "flex" }}>
							<TextField
								variant={variant}
								value={valorProposta}
								fullWidth
								label="Valor da Proposta"
								sx={{
									backgroundColor: "white",
								}}
								onChange={(event) => handleCurrency(event, setValorProposta)}
								InputProps={{
									startAdornment: <InputAdornment position="start">R$</InputAdornment>,
								}}
							/>
						</div>
					</div>

					<FinanciamentoAdicional
						fonteAdicional={fonteAdicional}
						setFonte={setFonte}
						setRazaoSocialFinanciadora={setRazaoSocialFinanciadora}
						setFinanciamentoAdicional={setFinanciamentoAdicional}
					/>
				</div>
				{/*FONTE ADICIONAL FINANCIAMENTO*/}
				{fonteAdicional ? (
					<div
						style={{
							fontWeight: "bold",
							flexDirection: "row",
							display: "flex",
							alignItems: "end",
							width: "100%",
							flexWrap: "wrap",
							columnGap: 10,
							rowGap: 10,
						}}
					>
						<div style={{ flexGrow: 1 }}>
							<p>Financiamento Externo</p>
							<TextField
								fullWidth
								variant={variant}
								sx={{ backgroundColor: "white" }}
								label={"Valor"}
								value={financiamentoAdicional}
								onChange={(event) => handleCurrency(event, setFinanciamentoAdicional)}
								InputProps={{
									startAdornment: <InputAdornment position="start">R$</InputAdornment>,
								}}
							/>
						</div>
						<TextField
							fullWidth
							variant={variant}
							label="Razão Social da Entidade Financiadora"
							sx={{
								marginLeft: "auto",
								width: "80%",
								backgroundColor: "white",
								flexGrow: 1,
							}}
							onChange={(event) => setRazaoSocialFinanciadora(event.target.value)}
						/>
					</div>
				) : null}

				<Button onClick={handleSubmission} sx={{ backgroundColor: "black", color: "white", marginTop: "20px" }}>
					ENVIAR
				</Button>
			</div>
		);
}

function FinanciamentoAdicional({ fonteAdicional, setFonte, setRazaoSocialFinanciadora, setFinanciamentoAdicional }) {
	return (
		<div
			style={{
				backgroundColor: "transparent",
				marginTop: "auto",
				flexDirection: "row",
				display: "flex",
			}}
		>
			<p>Caso possua outra fonte de financiamento adicione-a aqui</p>
			<Button
				onClick={() => {
					setRazaoSocialFinanciadora("");
					setFinanciamentoAdicional(0);
					setFonte(!fonteAdicional);
				}}
				style={{
					backgroundColor: fonteAdicional ? "red" : "black",
					color: "white",
					marginLeft: "10px",
					height: "fit-content",
					alignSelf: "center",
				}}
			>
				{fonteAdicional ? "Remover" : "Adicionar"}
			</Button>
		</div>
	);
}

function ToggleDocAdicional({ toggleAdicional, setToggleAdicional, setDocAdicional }) {
	return (
		<div
			style={{
				flexDirection: "row",
				display: "flex",
				width: "100%",
				marginTop: "auto",
			}}
		>
			<p style={{ marginLeft: "auto" }}>Precisa adicionar um documento extra?</p>

			<Button
				onClick={() => {
					setDocAdicional("");
					setToggleAdicional(!toggleAdicional);
				}}
				style={{
					backgroundColor: toggleAdicional ? "red" : "black",
					color: "white",
					marginLeft: "10px",
					height: "fit-content",
					alignSelf: "center",
				}}
			>
				{toggleAdicional ? "Remover" : "Adicionar"}
			</Button>
		</div>
	);
}

function Anexos({ proposta, setProposta, anuencia, setAnuencia, planilhaOrcamentaria, setPlanilhaOrcamentaria }) {
	return (
		<div
			style={{
				display: "flex",
				backgroundColor: "transparent",
				justifyContent: "space-between",
				rowGap: 10,
				columnGap: 10,
				flexWrap: "wrap",
			}}
		>
			<Anexo pdf text="Proposta" required={true} value={proposta} setValue={setProposta} />
			<Anexo
				pdf
				text="Carta de Anuência da Instituição"
				required={true}
				value={anuencia}
				setValue={setAnuencia}
			/>
			<Anexo
				text="Planilha Orçamentária"
				required={true}
				excel
				value={planilhaOrcamentaria}
				setValue={setPlanilhaOrcamentaria}
				template="planilha_orcamentaria_cat1.xlsx"
			/>
		</div>
	);
}
