import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Anexo from "../../../components/anexo";
import notificar from "../../../components/notificar";

export default function FormDialog({ open, setOpen, variant, reloadData, setLoading, id }) {
	const [anexo, setAnexo] = React.useState(null);
	const [comentario, setComentario] = React.useState("");
	let excel;
	let pdf;
	let title;
	let content;

	switch (variant) {
		case "bolsa":
			title = "Ativar Bolsa";
			content = "Iniciar pagamento do bolsista";
			break;
		case "prorrogacao":
			title = "Prorrogação de Prazo";
			content = "Solicitar prorrogação do prazo de execução do projeto";
			break;
		case "substituicao":
			title = "Substituir Bolsista";
			content = "Substituir bolsista do projeto";
			break;
		case "transposicao":
			title = "Transposição de Recursos";
			content = "Anexe a nova planilha orçamentária já incluindo a alteração solicitada";
			excel = true;
			break;
		case "errata":
			title = "Errata de Documentos";
			content = "Anexe o novo documento e justifique o motivo da alteração";
			excel = true;
			pdf = true;
			break;
		default:
			break;
	}

	const handleClose = () => {
		setAnexo(null);
		setComentario("");
		setOpen(false);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{content}</DialogContentText>
					<TextField
						margin="dense"
						autoFocus
						id="name"
						label="Comentário para o Assessor"
						type="text"
						fullWidth
						value={comentario}
						onChange={(event) => setComentario(event.target.value)}
					/>
					<Anexo
						sx={{ boxShadow: "none" }}
						text="Documento"
						value={anexo}
						setValue={setAnexo}
						excel={excel}
						pdf={pdf}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button
						onClick={() =>
							handleSubmission(anexo, comentario, title, handleClose, reloadData, setLoading, id)
						}
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const handleSubmission = async (anexo, comentario, tipo, handleClose, reloadData, setLoading, id) => {
	setLoading(true);
	const formData = new FormData();
	formData.append("arquivo", anexo);
	formData.append("tipo", tipo);
	formData.append("comentario", comentario);
	formData.append("id", id);

	const userData = JSON.parse(sessionStorage.getItem("user"));
	const token = userData.state.token;

	const response = await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/solicitar-alteracao`,
		{
			method: "PUT",
			body: formData,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	).catch((err) => console.error(err));

	if (response.status === 200) {
		notificar("Solicitação enviada com sucesso!", "success");
		handleClose();
		reloadData();
	} else {
		notificar("Erro ao enviar solicitação", "error");
	}
};
