export async function listaTodos(query) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/lista-todos?id_agrisus=${query}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	).then((response) => response.json());
}

export async function listaArquivo(query) {
	let user = JSON.parse(sessionStorage.getItem("user"));
	let token = user.state.token;

	return await fetch(
		`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/proposta/lista-arquivo?id_agrisus=${query}`,
		{
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	).then((response) => response.json());
}
