import { useEffect, useState } from "react";
import Categoria1 from "./categoria1";
import Categoria2 from "./categoria2";
import Categoria3 from "./categoria3";
import { ToggleButton, ToggleButtonGroup, Box, TextField } from "@mui/material";
import { textStyle } from "../../../styles/text";

export default function EmitirParecer({ data, reloadData }) {
	const [selected, setSelected] = useState(0);
	const [disabled, setDisabled] = useState(true);

	const handleCategoria = () => {
		if (data.categoria === 1) return <Categoria1 data={data} reloadData={reloadData} />;
		else if (data.categoria === 2) return <Categoria2 data={data} reloadData={reloadData} />;
		else if (data.categoria === 3) return <Categoria3 data={data} reloadData={reloadData} />;
	};

	useEffect(() => {
		if (!data.parecer_dados) {
			setSelected(1);
		} else {
			setDisabled(false);
		}
	}, []);

	return (
		<Box>
			<ToggleButtonGroup
				exclusive
				value={selected}
				onChange={(event, value) => {
					if (value !== null) setSelected(value);
				}}
			>
				<ToggleButton value={0} disabled={disabled}>
					Resumido
				</ToggleButton>
				<ToggleButton value={1}>Completo</ToggleButton>
			</ToggleButtonGroup>

			{selected === 0 ? <ParecerResumido data={data} /> : handleCategoria()}
		</Box>
	);
}

const ParecerResumido = ({ data }) => {
	const sx = {
		backgroundColor: "white",
	};

	if (data.parecer_dados) {
		let parecer_dados = JSON.parse(data.parecer_dados);

		return (
			<Box sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
				<p style={textStyle.header}>Parecer do Comitê Científico</p>
				<TextField label="Pontuação" value={parecer_dados.media} variant="outlined" sx={sx} />
				{data.parecer === "APROVADO" ? (
					<>
						<TextField
							label="Orçamento Aprovado"
							value={parecer_dados.sugerirOrcamento ? parecer_dados.valorSugerido : data.valor_do_projeto}
							variant="outlined"
							sx={sx}
						/>
						<TextField
							label="Motivo"
							value={parecer_dados.comentario}
							fullWidth
							variant="outlined"
							sx={sx}
						/>
					</>
				) : null}
				<TextField
					label="Deliberação do Assessor"
					value={parecer_dados.deliberacao}
					fullWidth
					variant="outlined"
					sx={sx}
				/>
			</Box>
		);
	}
};
