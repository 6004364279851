import Anexo from "../../../components/anexoDownload";
import React from "react";
import { textStyle } from "../../../styles/text";
import Status from "./status";

const Anexos = ({ data }) => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			flexWrap: "wrap",
			justifyContent: "space-between",
			columnGap: 10,
			rowGap: 10,
		}}
	>
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				textAlign: "center",
			}}
		>
			<p style={{ ...textStyle.header }}>Documentação</p>
			<Status dados={data} />
		</div>
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-evenly",
				flexWrap: "wrap",
				rowGap: 10,
				columnGap: 10,
			}}
		>
			{data.proposta ? <Anexo text="Proposta" file={data.proposta} /> : null}

			{data.carta_de_anuencia ? (
				<Anexo text="Carta de anuência da Instituição" file={data.carta_de_anuencia} />
			) : null}

			{data.docs_dos_bolsistas ? <Anexo text="Documentação do Bolsista" file={data.docs_dos_bolsistas} /> : null}

			{data.plano_de_recursos ? <Anexo text="Planilha Orçamentária" file={data.plano_de_recursos} /> : null}

			{data.doc_adicional ? <Anexo text="Documento extra adicionado" file={data.doc_adicional} /> : null}

			{data.cadastro_de_coordenador ? (
				<Anexo text="Cadastro do coordenador" file={data.cadastro_de_coordenador} />
			) : null}

			{data.protocolo_abertura_evento ? (
				<Anexo text="Protocolo de abertura do evento" file={data.protocolo_abertura_evento} />
			) : null}

			{data.protocolo_abertura_pesquisa ? (
				<Anexo text="Protocolo de abertura de pesquisa" file={data.protocolo_abertura_pesquisa} />
			) : null}

			{data.formulario_concessao_bolsa ? (
				<Anexo text="Formulário de concessão de bolsa" file={data.formulario_concessao_bolsa} />
			) : null}
			{data.plano_de_atividades ? (
				<Anexo text="Plano de atividades do bolsista" file={data.plano_de_atividades} />
			) : null}
		</div>
	</div>
);

export default Anexos;
