import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField } from "@mui/material";
import { atualizarCoordenador } from "./api";
import notificar from "../../../components/notificar";
import { detalhes } from "./api";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function ModificarCoordenador(props) {
	const [nome, setNome] = React.useState(null);
	const [email, setEmail] = React.useState(null);
	const [uf, setUf] = React.useState(null);
	const [cpf, setCpf] = React.useState(null);
	const [instituicao, setInstituicao] = React.useState(null);
	const [senha, setSenha] = React.useState("");
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		if (props.id !== null) {
			setLoading(true);
			detalhes(props.id)
				.then((query) => {
					if (query.status === 200) {
						query
							.json()
							.then((json) => {
								setNome(json.nome);
								setEmail(json.email);
								setUf(json.uf);
								setCpf(json.cpf);
								setInstituicao(json.instituicao);
								setSenha("");
							})
							.then(() => setLoading(false));
					} else {
						notificar("Erro ao carregar dados do coordenador!", "error");
					}
				})
				.catch((error) => {
					notificar("Erro ao carregar dados do coordenador!", "error");
					console.error(error);
				});
		}
	}, [props.id]);

	const handleUpdate = async () => {
		try {
			let query = await atualizarCoordenador({ id: props.id, nome, email, senha, cpf, uf, instituicao });

			if (query.status === 200) {
				let json = await query.json();
				notificar(json, "success");
				handleClose();
				props.reloadData();
			} else {
				let json = await query.json();
				notificar(json, "error");
			}
		} catch (error) {
			notificar("Erro ao atualizar coordenador!", "error");
			console.error(error);
		}
	};

	const handleClose = () => {
		props.setOpen(false);
	};

	if (!isLoading)
		return (
			<div>
				<Dialog open={props.open} onClose={handleClose} TransitionComponent={Transition}>
					<DialogTitle>Atualizar Cadastro do Coordenador</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Caso deixe o campo de senha em branco, a senha atual será mantida.
						</DialogContentText>
						<TextField
							value={nome}
							onChange={(event) => setNome(event.target.value)}
							autoFocus
							margin="dense"
							id="name"
							label="Nome"
							type="text"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
						/>
						<TextField
							value={instituicao}
							onChange={(event) => setInstituicao(event.target.value)}
							autoFocus
							margin="dense"
							id="instituicao"
							label="Instituição"
							type="text"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
						/>
						<TextField
							value={cpf}
							onChange={(event) => setCpf(event.target.value)}
							autoFocus
							margin="dense"
							id="cpf"
							label="CPF"
							type="text"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
						/>
						<TextField
							value={uf}
							onChange={(event) => setUf(event.target.value)}
							autoFocus
							margin="dense"
							id="uf"
							label="UF"
							type="text"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
							helperText="Ex: SP, RJ, MG, etc."
						/>
						<TextField
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							autoFocus
							margin="dense"
							id="email"
							label="Email"
							type="email"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
						/>
						<TextField
							autoComplete="off"
							value={senha}
							onChange={(event) => setSenha(event.target.value)}
							autoFocus
							margin="dense"
							id="password"
							label="Nova Senha"
							type="password"
							fullWidth
							variant="standard"
							sx={{ marginTop: "10px" }}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Voltar</Button>
						<Button onClick={handleUpdate}>Atualizar</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
}
